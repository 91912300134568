import React from "react";

import TaskList from "./TaskList";
import { TaskCacheProvider } from "./TaskCacheProvider";
import { useAuthenticationRef } from "../../containers/AuthorisationContainer";

import styles from "./CommandForge.module.css";

const CommandForge: React.FC =    () => {
    const authRef = useAuthenticationRef();
    return (
        <TaskCacheProvider authRef={authRef}>
            <div id={"command-forge"} className={styles.commandForge}>
                <TaskList />
            </div>
        </TaskCacheProvider>
    );
}

export default CommandForge;
