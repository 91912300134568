import { useEffect, RefObject } from "react";

export const useCopyHandler = (refObject: RefObject<HTMLDivElement>) => {
    useEffect(() => {
        const copyHandler = (event: ClipboardEvent) => {
            if (!refObject.current) {
                return;
            }

            const selection = window.getSelection();
            if (!selection || selection.type === "None") {
                return;
            }

            const parent = refObject.current;
            const range = selection.getRangeAt(0);
            if (!parent.contains(range.commonAncestorContainer)) {
                return;
            }

            const rows = parent.querySelectorAll<HTMLDivElement>('div[role="row"]');
            const clipboardContent: string[] = [];
            let isSingleCellSelected = false;
            rows.forEach((row) => {
                const rowRange = document.createRange();
                rowRange.selectNodeContents(row);
                if (
                    range.compareBoundaryPoints(Range.END_TO_START, rowRange) <= 0 &&
                    range.compareBoundaryPoints(Range.START_TO_END, rowRange) >= 0
                ) {
                    const cells = Array.from(row.children)
                        .filter(
                            (child) =>
                                (child.tagName === "SPAN" || child.tagName === "BUTTON") &&
                                !child.hasAttribute("data-nocopy")
                        )
                        .map((child) => {
                            const element = child as HTMLElement;
                            if (range.intersectsNode(child)) {
                                const titleValue = element.getAttribute("title");
                                isSingleCellSelected =
                                    range.toString().trim() ===
                                    (titleValue?.trim() || element.textContent?.trim() || "");
                                return titleValue?.trim() || element.textContent?.trim() || "";
                            }
                            return "";
                        })
                        .filter((cell) => cell !== "");
                    if (cells.length > 0) {
                        clipboardContent.push(cells.join("\t"));
                    }
                }
            });
            if (isSingleCellSelected && clipboardContent.length === 1) {
                event.clipboardData?.setData("text/plain", clipboardContent[0]);
            } else if (clipboardContent.length > 0) {
                event.clipboardData?.setData("text/plain", clipboardContent.join("\n"));
            }

            if (clipboardContent.length > 0) {
                event.preventDefault();
            }
        };
        document.addEventListener("copy", copyHandler);
        return () => {
            document.removeEventListener("copy", copyHandler);
        };
    }, [refObject]);
};
