import React, { createContext, useContext, useState, useCallback } from "react";
import { TaskDetails, PagedResponse } from "./types";
import { config } from "../../config";
import { getAuthHeaders } from "../../machines/layer/loaders";
import camelcaseKeys from 'camelcase-keys';

type TaskCacheContextType = {
    fetchTaskDetails: (ids: string[]) => void;
    taskDetails: Record<string, TaskDetails>;
};

const defaultCacheLifetime = 3600 * 1000;
const TaskCacheContext = createContext<TaskCacheContextType | null>(null);

export const TaskCacheProvider: React.FC<React.PropsWithChildren<{ authRef: React.RefObject<string> }>> = ({ children, authRef }) => {
    const [taskDetails, setTaskDetails] = useState<Record<string, { data: TaskDetails; fetchedAt: number }>>({});

    const fetchTaskDetails = useCallback(async (ids: string[]) => {
        const idsToFetch = ids.filter(
            (id) => !taskDetails[id] || Date.now() - taskDetails[id].fetchedAt > defaultCacheLifetime
        );

        if (idsToFetch.length > 0) {
            const details: Record<string, TaskDetails> = {};

            for (let i = 0; i < idsToFetch.length; i += 100) {
                const batch = idsToFetch.slice(i, i + 100);

                try {
                    const response = await fetch(`${config.schedulerApi}/tasks?id=${batch.join(",")}`, {
                        method: "GET",
                        ...getAuthHeaders(authRef.current),
                    });

                    if (!response.ok) {
                        console.error("Failed to fetch batch:", response.statusText);
                        continue;
                    }

                    const pagedResponse: PagedResponse<TaskDetails> = await response.json();

                    // Process the response data
                    pagedResponse.data.forEach((task) => {
                        // @ts-ignore
                        const normalizedTask: TaskDetails = camelcaseKeys(task, { deep: true });
                        // console.log("[commandForge]", normalizedTask);
                        details[normalizedTask.id] = normalizedTask;
                    });
                } catch (error) {
                    console.error(`Error fetching batch starting at index ${i}:`, error);
                }
            }

            setTaskDetails((prev) => ({
                ...prev,
                ...Object.fromEntries(
                    Object.entries(details).map(([id, data]) => [id, { data, fetchedAt: Date.now() }])
                ),
            }));
        }
    }, [authRef, taskDetails]);

    const cachedDetails = useCallback(() => {
        const validDetails: Record<string, TaskDetails> = {};
        const now = Date.now();

        for (const [id, { data, fetchedAt }] of Object.entries(taskDetails)) {
            if (now - fetchedAt <= defaultCacheLifetime) {
                validDetails[id] = data;
            }
        }

        return validDetails;
    }, [taskDetails]);

    return (
        <TaskCacheContext.Provider
            value={{
                fetchTaskDetails,
                taskDetails: cachedDetails(),
            }}
        >
            {children}
        </TaskCacheContext.Provider>
    );
};

export const useTaskDetailsCache = (): TaskCacheContextType => {
    const context = useContext(TaskCacheContext);
    if (!context) {
        throw new Error("useTaskDetailsCache must be used within a TaskCacheProvider");
    }
    return context;
};
