import { FC, useMemo } from "react"
import { Box } from "./Box"
import styles from './LoadingPane.module.css'
import { useFile } from "../containers/FileContainer"
import { Translate } from "../containers/I18nContainer"

const ApprovalPane: FC = () => {
    const { context, send } = useFile()
    const selection = useMemo(() => context.groundTruths
        .find((gt) =>
            gt.id === context.selection?.id) ?? null

        , [context.selection, context.groundTruths])

    if (!selection) {
        return null
    }

    if (selection.approval_status === 'approved') {
        return <div
            key={'fullLayout'}
            className={context.selection?.id !== undefined
                ? styles.fullLayout
                : styles.fullLayoutHidden}
            onClick={() => {
                send({
                    type: 'setSelection'
                })
            }}
        >
            <Box key={'scanView'} fill flex center>
                <div className={styles.paneBody}>
                    <div><b>label:</b> {selection?.user_label ?? 'unknown'}</div>
                    <div><b>labeller:</b> {selection?.approved_by_name ?? 'unknown'}</div>
                    <button
                        onClick={() => {
                            send({
                                type: 'updateSelectionState',
                                status: 'pending'
                            })
                        }}
                    ><Translate id={'buttons.resetToPending'} wrapper={false} /></button>
                </div>
            </Box>
        </div >
    }

    return <div
        key={'fullLayout'}
        className={context.selection?.id !== undefined
            ? styles.fullLayout
            : styles.fullLayoutHidden}
        onClick={() => {
            send({
                type: 'setSelection'
            })
        }}
    >
        <Box key={'scanView'} fill flex center>
            <div className={styles.paneBody}>
                <div><b>label:</b> {selection?.user_label ?? 'unknown'}</div>
                <div><b>labeller:</b> {selection?.approved_by_name ?? 'unknown'}</div>
                <button
                    onClick={() => {
                        send({
                            type: 'updateSelectionState',
                            status: 'approved'
                        })
                    }}
                ><Translate id={'buttons.approve'} wrapper={false} /></button>
                <button
                    onClick={() => {
                        send({
                            type: 'updateSelectionState',
                            status: 'rejected'
                        })
                    }}
                ><Translate id={'buttons.reject'} wrapper={false} /></button>
            </div>
        </Box>
    </div >
}

export {
    ApprovalPane,
}