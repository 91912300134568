import React from "react";

import { Task, TaskDetails } from "./types";
import getStatusIcon from "./StatusIcon";
import { MdCancel, MdExpandLess, MdExpandMore, MdRefresh } from "react-icons/md";
import { config } from "../../config";
import { getAuthHeaders } from "../../machines/layer/loaders";
import TaskHistory from "./TaskHistory";
import { useAuthenticationRef } from "../../containers/AuthorisationContainer";

import styles from "./TaskItem.module.css";

type TaskItemProps = {
    task: Task;
    isExpanded: boolean;
    onExpand: () => void;
    taskDetails?: TaskDetails;
};

const TaskItem: React.FC<TaskItemProps> = ({ task, isExpanded, onExpand, taskDetails }) => {
    const authRef = useAuthenticationRef();
    const handleCancel = async () => {
        try {
            await fetch(`${config.schedulerApi}/tasks/status`, {
                method: "POST",
                ...getAuthHeaders(authRef.current),
                body: JSON.stringify({
                    jobId: task.id,
                    status: "Cancelled",
                    message: "Cancelled via Bootcamp UI",
                }),
            });
        } catch (error) {
            console.error("Failed to cancel task", error);
        }
    };

    const handleRetry = async () => {
        try {
            await fetch(`${config.schedulerApi}/tasks/status`, {
                method: "POST",
                ...getAuthHeaders(authRef.current),
                body: JSON.stringify({
                    jobId: task.id,
                    status: "Pending",
                    message: "Retry via Bootcamp UI",
                }),
            });
        } catch (error) {
            console.error("Failed to retry task", error);
        }
    };

    const statusDateString = new Date(task.createdDate * 1000).toLocaleDateString(undefined, {
        day: "2-digit",
        month: "short",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
    });

    return (
        <>
            <div className={styles.taskItem} role={"row"}>
                <span title={task.status}>{getStatusIcon(task.status)} tabindex={"0"}</span>
                <span>{task.id}</span>
                <span>{taskDetails?.taskType ?? ""}</span>
                <span
                      title={`${taskDetails?.taskData?.source || ""} → ${taskDetails?.taskData?.destination || ""}`}>{taskDetails?.taskData?.source || ""} → {taskDetails?.taskData?.destination || ""}</span>
                <span title={statusDateString}>{task.createdDateString}</span>
                <span className={styles.actions} data-nocopy={true}>
                    <>
                        {(task.status === "Pending" || task.status === "Failed" || task.status === "Unknown") ? (
                            <button onClick={handleCancel}>
                                <MdCancel size={24}/>
                            </button>
                        ) : (<div className={styles.actionSpacer}/>)
                        }

                        {(task.status === "Cancelled" || task.status === "Failed" || task.status === "Unknown") ? (
                            <button onClick={handleRetry}>
                                <MdRefresh size={24}/>
                            </button>
                        ) : (<div className={styles.actionSpacer}/>)
                        }
                        </>
                </span>
                <span data-nocopy={true}>
                    <button onClick={onExpand}>
                    {isExpanded ? <MdExpandLess size={24}/> : <MdExpandMore size={24}/>}
                    </button>
                </span>
            </div>
            {isExpanded && taskDetails && (
                <div className={styles.taskDetails}>
                    <div className={styles.taskData}>
                        <div>Created: {new Date(taskDetails.createdAt).toLocaleDateString(undefined, {
                            day: "2-digit",
                            month: "short",
                            year: "numeric",
                            hour: "2-digit",
                            minute: "2-digit",
                            hour12: false,
                        })}</div>
                        <div>Priority: {taskDetails.priority}</div>
                        Task Details:
                        <ul>
                            {<li>Job ID: {taskDetails?.id}</li>}
                            {taskDetails?.parentJobId && <li>Parent Job ID: {taskDetails?.parentJobId}</li>}
                            {taskDetails?.taskData.source && <li>Source: {taskDetails?.taskData.source}</li>}
                            {taskDetails?.taskData.destination && <li>Destination: {taskDetails?.taskData.destination}</li>}
                            {taskDetails?.taskData.palettes && <li>Palettes: {taskDetails?.taskData.palettes}</li>}
                            {taskDetails?.taskData.renderer && <li>Renderer: {taskDetails?.taskData.renderer}</li>}
                            {taskDetails?.taskData.viewCounts && <li>View Counts: {taskDetails?.taskData.viewCounts}</li>}
                            {taskDetails?.taskData.compressionLevel && <li>Compression Level: {taskDetails?.taskData.compressionLevel}</li>}
                            {taskDetails?.taskData.scaleFactors && <li>Scale Factors: {taskDetails?.taskData.scaleFactors}</li>}
                            {taskDetails?.taskData.interpolation && <li>Interpolation: {taskDetails?.taskData.interpolation}</li>}
                            {taskDetails?.taskData.plasticDestination && <li>Plastic Destination: {taskDetails?.taskData.plasticDestination}</li>}
                            {taskDetails?.taskData.blueRedDestination && <li>Blue-Red Destination: {taskDetails?.taskData.blueRedDestination}</li>}
                            {taskDetails?.taskData.includePath && <li>Include Path: {taskDetails?.taskData.includePath}</li>}
                            {taskDetails?.taskData.excludePath && <li>Exclude Path: {taskDetails?.taskData.excludePath}</li>}
                            {taskDetails?.taskData.inputList && <li>Input List: {taskDetails?.taskData.inputList}</li>}
                        </ul>
                        <TaskHistory taskId={task.id}/>
                    </div>

                </div>
            )}
        </>
    );
};

export default React.memo(TaskItem);
