import React from "react";

import styles from './Pagination.module.css';

type PaginationProps = {
    totalRecords: number;
    pageSize: number;
    currentPage: number;
    totalPages: number;
    onPageChange: (page: number) => void;
    onPageSizeChange: (pageSize: number) => void;
};

const Pagination: React.FC<PaginationProps> = ({
                                                   currentPage,
                                                   totalPages,
                                                   totalRecords,
                                                   pageSize,
                                                   onPageChange,
                                                   onPageSizeChange,
                                               }) => {
    const handlePrevious = () => {
        if (currentPage > 1) onPageChange(currentPage - 1);
    };

    const handleNext = () => {
        if (currentPage < totalPages) onPageChange(currentPage + 1);
    };

    const getPageNumbers = () => {
        if (totalPages <= 10) {
            return Array.from({ length: totalPages }, (_, i) => i + 1);
        }

        const pages = new Set<number>();
        const rangeStart = Math.max(3, currentPage - 5);
        const rangeEnd = Math.min(totalPages - 2, currentPage + 5);

        pages.add(1).add(2);
        for (let i = rangeStart; i <= rangeEnd; i++) {
            pages.add(i);
        }
        pages.add(totalPages - 1).add(totalPages);

        return Array.from(pages).sort((a, b) => a - b);
    };

    const pages = getPageNumbers();

    const startRecord = (currentPage - 1) * pageSize + 1;
    const endRecord = Math.min(currentPage * pageSize, totalRecords);

    const pageSizeOptions = [
        ...Array.from({ length: 9 }, (_, i) => (i + 1) * 10),
        ...Array.from({ length: 10 }, (_, i) => 100 + i * 100),
    ];

    return (
        <div className={styles.pagination}>
            {totalRecords > 0 ? (
                <>
                    <div className={styles.paginationInfo}>
                        <span>
                            {startRecord} to {endRecord} of {totalRecords}
                        </span>
                        <select
                            className={styles.paginationSelect}
                            value={pageSize}
                            onChange={(e) => onPageSizeChange(Number(e.target.value))}
                        >
                            {pageSizeOptions.map((size) => (
                                <option key={size} value={size}>
                                    {size}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div className={styles.paginationControls}>
                        <button
                            className={styles.paginationButton}
                            disabled={currentPage === 1}
                            onClick={handlePrevious}
                        >
                            Previous
                        </button>
                        {pages.map((page, idx) => (
                            <React.Fragment key={page}>
                                {idx > 0 && page > pages[idx - 1] + 1 && (
                                    <span>...</span>
                                )}
                                <button
                                    className={`${styles.paginationButton} ${
                                        currentPage === page ? styles.paginationButtonActive : ""
                                    }`}
                                    onClick={() => onPageChange(page)}
                                >
                                    {page}
                                </button>
                            </React.Fragment>
                        ))}
                        <button
                            className={styles.paginationButton}
                            disabled={currentPage === totalPages}
                            onClick={handleNext}
                        >
                            Next
                        </button>
                    </div>
                </>
            ) : (
                <div>No results</div>
                )
            }

        </div>
    );
};

export default Pagination;
