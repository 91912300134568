import React, { PropsWithChildren, useState } from 'react'
import { ScanItemResponseConclusion } from '@bbai-dartmouth/dartmouth-model-viewer-lib'
import styles from './ReportFileControls.module.css'
import { FileResult } from '../types'
import { BiSolidLeftArrowAlt, BiSolidRightArrowAlt } from "react-icons/bi";
import { useFile } from '../containers/FileContainer'
import { Translate } from '../containers/I18nContainer'

const stripPath = (filename: string, viewFullPath: boolean): string => {
    if (viewFullPath) {
        return filename
    }
    return (filename.split('/').pop() ?? '')
}

const fileResultToTitle = (file: FileResult, fullPath: boolean = false) => {
    if (file?.meta?.api) {
        return file.name
    }
    return stripPath(file.path, fullPath)
}

interface ReportFileControlsProps {
    selection?: ScanItemResponseConclusion
    visible?: boolean
    threatCount?: number
    customConclusions: ScanItemResponseConclusion[]
}

export const ReportFileControls: React.FC<PropsWithChildren<ReportFileControlsProps>> = ({ children, visible, customConclusions, selection, threatCount }) => {
    const { currentState, context: { adminMode, mode, file, report, nextFile, prevFile }, send } = useFile()
    const [viewFullPath, setViewFullPath] = useState<boolean>(false)

    if (file === undefined) {
        return null
    }

    const selectFile = (file: FileResult) => {
        if (customConclusions.filter(({hidden})=>!hidden).length > 0) {
            return send({
                type: 'saveData',
                mode: 'conclusions',
                file: file,
            })
        }
        return send({
            type: 'selectFile',
            file,
        })
    }

    return <div className={visible ? styles.reportFileControlsVisible : styles.reportFileControls}>
        <div
            className={styles.reportFileButtonWrapper}
        >
            <BiSolidLeftArrowAlt
                className={prevFile
                    ? styles.reportFileButton
                    : styles.reportFileButtonDisabled}
                title={prevFile?.path}
                onClick={() =>
                    selectFile(prevFile!)}
            />
            <div
                className={styles.reportFileLabel}
            >
                <div
                    className={adminMode
                        ? styles.reportFileModeAdmin
                        : styles.reportFileMode}
                    onClick={() => send({
                        type: 'toggleApprovalMode'
                    })}
                >
                    {mode === 'project' && ['viewingFile', 'approvingFile'].includes(currentState)
                        ? <Translate id={`modes.project.${currentState}`} wrapper={false} />
                        : null}
                </div>
                <div
                    className={styles.reportFileLabelPath}
                    title={file.path}
                >{fileResultToTitle(file, viewFullPath)}{children}</div>
                <div className={styles.reportFileLabelDetail}>
                    <Translate id={'threats.threatCount'} data={threatCount} wrapper={false} />
                    <span>{selection?.category ?? selection?.label}</span>
                </div>
            </div>
            <BiSolidRightArrowAlt
                className={nextFile
                    ? styles.reportFileButton
                    : styles.reportFileButtonDisabled}
                title={nextFile?.path}
                onClick={() =>
                    selectFile(nextFile!)}
            />
        </div>
    </div>
}
