import React, { useState, useEffect } from "react";

import Select, { ClassNamesConfig } from "react-select";

import styles from './Filters.module.css';

interface FilterOptions {
    label: string;
    value: string;
}

export const taskTypeOptions = [
    {
        label: "Conversion Tasks",
        options: [
            { value: "analogic-to-dcs", label: "analogic-to-dcs" },
            { value: "dcs-to-drt", label: "dcs-to-drt" },
            { value: "dcs-to-image", label: "dcs-to-image" },
            { value: "export-3d-ground-truths", label: "export-3d-ground-truths" },
            { value: "extract-from-tarball", label: "extract-from-tarball" },
            { value: "extract-ground-truths", label: "extract-ground-truths" },
            { value: "hif-to-dcs", label: "hif-to-dcs" },
            { value: "render-bounding-boxes", label: "render-bounding-boxes" },
            { value: "update-2d-gt", label: "update-2d-gt" },
        ],
    },
    {
        label: "Generated Tasks",
        options: [
            { value: "generate-analogic-to-dcs-tasks", label: "generate: analogic-to-dcs" },
            { value: "generate-dcs-to-drt-tasks", label: "generate: dcs-to-drt" },
            { value: "generate-dcs-to-image-tasks", label: "generate: dcs-to-image" },
            { value: "generate-extract-from-tarball-tasks", label: "generate: extract-from-tarball" },
            { value: "generate-hif-to-dcs-tasks", label: "generate: hif-to-dcs" },
            { value: "generate-render-bounding-boxes-tasks", label: "generate: render-bounding-boxes" },
            { value: "generate-update-2d-gt-tasks", label: "generate: update-2d-gt" },
        ],
    },
    {
        label: "Utility Tasks",
        options: [
            { value: "count-files", label: "count-files" },
            { value: "list-files", label: "list-files" },
            { value: "file-command", label: "file-command" },
        ],
    },
];

const queueTypeOptions: FilterOptions[] = [
    { value: "standard", label: "standard" },
    { value: "gpu", label: "gpu" },
    { value: "eagle", label: "eagle" },
];

const statusOptions: FilterOptions[] = [
    { value: "Pending", label: "Pending" },
    { value: "Queued", label: "Queued" },
    { value: "InProgress", label: "In Progress" },
    { value: "Completed", label: "Completed" },
    { value: "Failed", label: "Failed" },
    { value: "Cancelled", label: "Cancelled" },
    { value: "Unknown", label: "Unknown" },
];

interface FiltersProps {
    onFiltersChange: (updatedUrl: string) => void;
}

const customStyles = {
    menuList: (provided: any) => ({
        ...provided,
        maxHeight: "none",
    }),
    menu: (provided: any) => ({
        ...provided,
        minWidth: "20rem",
    }),

};

const customClassNames: ClassNamesConfig<FilterOptions, true> = {
    container: () => styles.reactSelectContainer,
    control: () => styles.reactSelectControl,
};


const Filters: React.FC<FiltersProps> = ({ onFiltersChange }) => {
    const [taskType, setTaskType] = useState<FilterOptions[]>([]);
    const [queueType, setQueueType] = useState<FilterOptions[]>([]);
    const [status, setStatus] = useState<FilterOptions[]>([]);
    const [startDate, setStartDate] = useState<string>("");
    const [endDate, setEndDate] = useState<string>("");
    const [id, setId] = useState<string>("");
    const [parentId, setParentId] = useState<string>("");

    useEffect(() => {
        if (!startDate) {
            const oneHourAgo = new Date();
            oneHourAgo.setHours(oneHourAgo.getHours() - 1);

            const formattedDate = oneHourAgo.toISOString().slice(0, 16);
            setStartDate(formattedDate);
        }
    }, [startDate]);

    useEffect(() => {
        const queryParams = new URLSearchParams();

        if (taskType.length > 0) {
            queryParams.append("taskType", taskType.map((type) => type.value).join(","));
        }
        if (queueType.length > 0) {
            queryParams.append("queueType", queueType.map((type) => type.value).join(","));
        }
        if (status.length > 0) {
            queryParams.append("status", status.map((type) => type.value).join(","));
        }
        if (startDate) {
            queryParams.append("startDate", startDate);
        }
        if (endDate) {
            queryParams.append("endDate", endDate);
        }
        if (id) {
            queryParams.append("id", id);
        }
        if (parentId) {
            queryParams.append("parentId", parentId);
        }
        onFiltersChange(queryParams.toString());
    }, [taskType, queueType, status, startDate, endDate, id, parentId, onFiltersChange]);

    return (
        <div className={styles.filters}>
            <div className={styles.filterGroup}>
                <label>Task Type</label>
                <Select
                    isMulti
                    options={taskTypeOptions}
                    value={taskType}
                    onChange={(selected) => setTaskType(selected as FilterOptions[])}
                    classNamePrefix={"react-select"}
                    classNames={customClassNames}
                    styles={customStyles}
                    menuPlacement="auto"
                />
            </div>
            <div className={styles.filterGroup}>
                <label>Queue Type</label>
                <Select
                    isMulti
                    options={queueTypeOptions}
                    value={queueType}
                    onChange={(selected) => setQueueType(selected as FilterOptions[])}
                    classNamePrefix={"react-select"}
                    classNames={customClassNames}
                />
            </div>
            <div className={styles.filterGroup}>
                <label>Status</label>
                <Select
                    isMulti
                    options={statusOptions}
                    value={status}
                    onChange={(selected) => setStatus(selected as FilterOptions[])}
                    classNamePrefix="react-select"
                    classNames={customClassNames}
                />
            </div>
            <div className={styles.filterGroup}>
                <label>Start Date</label>
                <input
                    type="datetime-local"
                    value={startDate}
                    className={styles.dateInput}
                    onChange={(e) => setStartDate(e.target.value)}
                />
            </div>
            <div className={styles.filterGroup}>
                <label>End Date</label>
                <input
                    type="datetime-local"
                    value={endDate}
                    className={styles.dateInput}
                    onChange={(e) => setEndDate(e.target.value)}
                />
            </div>
            <div className={styles.filterGroup}>
                <label>ID</label>
                <input
                    type="text"
                    value={id}
                    className={styles.dateInput}
                    onChange={(e) => setId(e.target.value)}
                    placeholder="ID"
                />
            </div>
            <div className={styles.filterGroup}>
                <label>Parent ID</label>
                <input
                    type="text"
                    value={parentId}
                    className={styles.dateInput}
                    onChange={(e) => setParentId(e.target.value)}
                    placeholder="Parent ID"
                />
            </div>
        </div>
    );
};

export default Filters;
