export class CommandForgeUtils  {
    public static formatRelativeTime(timestamp: number): string {
        const createdDate = new Date(timestamp * 1000);
        const now = new Date();
        const diff = Math.max(0, now.getTime() - createdDate.getTime());

        const seconds = Math.floor(diff / 1000);
        const minutes = Math.floor(seconds / 60);
        const hours = Math.floor(minutes / 60);
        const days = Math.floor(hours / 24);

        if (days >= 1) {
            return createdDate.toLocaleDateString(undefined, {
                day: "2-digit",
                month: "short",
                year: "numeric",
                hour: "2-digit",
                minute: "2-digit",
                hour12: false,
            });
        } else if (hours >= 1) {
            return `${hours} ${hours === 1 ? "hour" : "hours"} ago`;
        } else if (minutes >= 1) {
            return `${minutes} ${minutes === 1 ? "minute" : "minutes"} ago`;
        } else {
            return `${seconds} ${seconds === 1 ? "second" : "seconds"} ago`;
        }
    }
}
