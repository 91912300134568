import { FC, PropsWithChildren, useMemo } from "react";
import { UserRoles } from "../types"
import { hasRoles, useAuthentication } from "../containers/AuthorisationContainer";
import { Link } from "react-router-dom";

type HasRoleProps = {
    any: UserRoles[]
    not?: UserRoles
    all?: UserRoles[]
} | {
    any?: UserRoles[]
    not?: UserRoles
    all: UserRoles[]
}

const HasRole: FC<PropsWithChildren<HasRoleProps>> = ({ any: anyRoles, all: allRoles, not: notRole, children }) => {
    const { roles } = useAuthentication()

    const userHasRoles = useMemo<boolean>(()=>
        hasRoles(roles, anyRoles, allRoles) && !(notRole && roles.includes(notRole))
    ,[roles, anyRoles, allRoles, notRole])

    if (userHasRoles) {
        return children
    }

    return null
}

const RoleLink: FC<PropsWithChildren<HasRoleProps & { to: string }>> = ({ to, any, all, not, children }) =>
    <HasRole any={any ?? []} all={all} not={not}>
        <Link to={to}>{children}</Link>
    </HasRole>

export {
    HasRole,
    RoleLink,
}
