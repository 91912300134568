import './SelectGroup.css'


export interface SelectOptionData {
    id: any
    label: string
}

interface SelectGroupProps {
    visible?: boolean
    value: any
    options: SelectOptionData[]
    filterOptions?: any[]
    label?: string
    onChange: (value: any) => void
}

const SelectGroup: React.FC<SelectGroupProps> = ({ visible, value, label, options, filterOptions, onChange }) =>
    visible ?
        <div className={'dartmouth__selectGroup'}>
            <div className={'dartmouth__selectGroupInput'}>
                {(filterOptions
                    ? options.filter(({ id }) => filterOptions.includes(id))
                    : options).map(({ id, label }) =>
                        <div
                            className={id === value
                                ? 'dartmouth__rangeSliderSelectItemSelected'
                                : 'dartmouth__rangeSliderSelectItem'}
                            key={id}
                            onClick={() =>
                                onChange(id)}
                        >{label}</div>)}
            </div>
            {label && <label
                className={'dartmouth__rangeSliderLabel'}
            >{label}</label>}
        </div>
        : null

interface InputGroupProps {
    visible?: boolean
    value: any
    label?: string
    onChange?: (value: any) => void
}
const InputGroup: React.FC<InputGroupProps> = ({ visible, value, label, onChange }) =>
    visible ?
        <div className={'dartmouth__selectGroup'}>
            <div className={'dartmouth__selectGroupInput'}>
                {onChange && <input
                    type={'text'}
                    value={value}
                    onChange={({target})=>onChange(target.value)}
                    className={'dartmouth__rangeSliderText'} /> || <div className={'dartmouth__rangeSliderText'} >{value}</div>}
            </div>
            {label && <label
                className={'dartmouth__rangeSliderLabel'}
            >{label}</label>}
        </div>
        : null
export {
    SelectGroup,
    InputGroup,
}
