import React, { useMemo, useState } from 'react'
import { BsFolderSymlinkFill, BsFolder, BsFileEarmarkBinaryFill, BsFileZipFill, BsXCircle } from 'react-icons/bs'
import type { IconType } from 'react-icons'
import prettyBytes from 'pretty-bytes'

import styles from './TreeView.module.css'
import { FileResult } from '../types'
import { shortFilename } from '../lib/files'
import { FullFileExtensionMatch } from '../types'


export interface SelectedFolder {
    tree: FileResult[],
    folderPath: string
}

interface TreeViewItemInfoProps {
    selected?: boolean
    file?: FileResult
}

const TreeViewItemInfo = ({ selected, file }: TreeViewItemInfoProps) =>
    selected
        ? <span className={styles.treeViewItemNote}>viewing</span>
        : <span className={styles.treeViewItemNote}>{(file?.type === 'file' && prettyBytes(file.size)) || (file?.type === 'folder' && `${file?.children} files`)}</span>


interface TreeViewItemProps {
    file?: FileResult
    label?: string
    onClick: () => void
    icon: IconType
    selected?: boolean
    tree?: FileResult[]
    hasData?: boolean
    minimal?: boolean
}

const TreeViewItem = ({ minimal, selected, hasData, tree = [], file, label, onClick, icon: IconElement }: TreeViewItemProps) =>
    <span
        className={[
            hasData
                ? styles.treeViewItemContainerHasData
                : '',
            selected
                ? styles.treeViewItemContainerSelected
                : styles.treeViewItemContainer].join(' ')}
        onClick={onClick}
    >
        <div className={styles.treeViewItemIcon}>
            <IconElement className={styles.treeViewItemIconSvg} />
        </div>
        <div className={styles.treeViewItemLabel} title={file?.ref}>
            {label ?? shortFilename(file?.name)}
        </div>
        {minimal
            ? null
            : <TreeViewItemInfo selected={selected} file={file} />}
    </span>


const getParentPath = (directory: string): string => {
    let parentPath = directory.endsWith('/')
        ? directory.replace(/[^/]+\/$/, '')
        : directory.replace(/\/[^/]+$/, '')
    if (parentPath.length < 1) {
        parentPath = '/'
    }
    return parentPath
}
interface TreeViewProps {
    tree: FileResult[]
    onFileSelect: (file: FileResult) => void
    file?: FileResult
    selectedFolderPath: string
    basePath?: string
    setSelectedFolderPath: (path: string) => void
    hidePath?: boolean
    minimal?: boolean
}

const TreeView: React.FC<TreeViewProps> = ({ tree, onFileSelect, file, basePath, selectedFolderPath, setSelectedFolderPath, hidePath, minimal }) => {
    const [searchTerm, setSearchTerm] = useState<string>('')
    const currentTree = useMemo(() => {
        const currentPath = selectedFolderPath.replace(basePath!, '/').replace(/^\/\//, '/')
        return tree
            .filter((node) => {
                if (searchTerm.length > 0) {
                    return node.visible && node.type === 'file' && node.name.indexOf(searchTerm) !== -1
                }
                return node.parent === currentPath && node.visible
            })
            .map((item) => ({
                ...item,
                hasData: tree.some(tf =>
                    tf.parent === item.parent && tf.name === `${item.name.replace(/\.min$/i, '').replace(FullFileExtensionMatch, '')}.json`)
            }))
    }, [selectedFolderPath, basePath, tree, searchTerm])

    return <div className={styles.treeViewContainer}>
        {hidePath
            ? null
            : <>
                <div className={styles.treeViewPathContainer}>{basePath}<span>{selectedFolderPath}</span></div>
                <div className={styles.treeViewSearchContainer}>
                    <input
                        type={'text'}
                        value={searchTerm}
                        placeholder={'search...'}
                        onChange={({ target }) => setSearchTerm(target.value.trim())}
                    />
                    {searchTerm.length > 0
                        ? <BsXCircle
                            onClick={() =>
                                setSearchTerm('')}
                        />
                        : null}
                </div>
            </>}
        {(selectedFolderPath !== basePath && selectedFolderPath !== '/')
            && <TreeViewItem
                key={'parentNode'}
                label={'..'}
                icon={BsFolderSymlinkFill}
                minimal={minimal}
                onClick={() => {
                    try {
                        setSelectedFolderPath(getParentPath(selectedFolderPath))
                    } catch (e: any) {
                        console.error('failure browsing to parent folder', e)
                    }
                }}
            />}
        {currentTree.map((node, i) =>
            node.type === 'folder'
                ? <TreeViewItem
                    key={node.id || i}
                    file={node}
                    icon={BsFolder}
                    minimal={minimal}
                    onClick={() => {
                        setSelectedFolderPath(node.path)
                    }}
                />
                : <TreeViewItem
                    key={node.id ?? i}
                    tree={tree}
                    hasData={node.hasData}
                    file={node}
                    minimal={minimal}
                    icon={/\.br$/.test(node.path)
                        ? BsFileZipFill
                        : BsFileEarmarkBinaryFill}
                    selected={node.path === file?.path}
                    onClick={() =>
                        node.path && onFileSelect(node)}
                />)}
    </div>
}

export {
    TreeView,
}
