import { AuthContextProps, useAuth } from "react-oidc-context"
import { Outlet } from "react-router-dom"
import { Login } from "../pages/Login"
import React, { createContext, RefObject, useContext, useEffect, useMemo, useRef, useState } from "react"
import { UserProfile, UserRoles } from "../types"
import { jwtDecode } from "jwt-decode"

interface AuthenticationState {
    roles: UserRoles[]
    profile: UserProfile
    user?: AuthContextProps
}

const AuthenticationContext = createContext<AuthenticationState | undefined>(undefined)

export const getUserRoles = (auth: any) => {
    if (auth?.roles) {
        return auth?.roles
    }
    const token = jwtDecode(auth.user.access_token) as any
    const roles = token?.realm_access?.roles ?? []
    return roles
}

interface AuthenticationLayoutProps {
    anyRole?: UserRoles[]
    allRoles?: UserRoles[]
}

const AuthorisationContainer: React.FC<AuthenticationLayoutProps> = ({ anyRole, allRoles }) => {
    const auth = useAuth()

    const [[profile, roles], setUserData] = useState<[UserProfile | undefined, UserRoles[]]>([undefined, []])
    const userHasRoles = useMemo(() => {
        return hasRoles(roles, anyRole, allRoles)
    }
        , [roles, anyRole, allRoles])

    React.useEffect(() => {
        if (auth.user) {
            //@ts-ignore
            const profile = auth.user.profile
            const roles = getUserRoles(auth)
            //@ts-ignore
            setUserData([profile, roles as UserRoles[]])
        }
    }, [auth])

    if (!auth.isAuthenticated) {
        return <>
            <Login />
        </>
    }

    if (!auth?.user || profile === undefined) {
        return <>loading...</>
    }

    if (!userHasRoles) {
        return <div>Not authorised to access this resource. You do not have the required role.{roles.join(', ')} {anyRole}</div>
    }

    return <AuthenticationContext.Provider value={{
        user: auth,
        roles,
        profile,
    }}>
        <Outlet />
    </AuthenticationContext.Provider>

}

const hasRoles = (userRoles: UserRoles[], anyRoles?: UserRoles[], allRoles?: UserRoles[]): boolean =>
    (anyRoles?.reduce((acc, role) =>
        (acc || userRoles.includes(role)), false) ?? true)
    &&
    (allRoles?.reduce((acc, role) =>
        (acc && userRoles.includes(role)), true) ?? true)

const useAuthentication = (): AuthenticationState => {
    const context = useContext(AuthenticationContext)
    if (context === undefined) {
        throw new Error('element is not in AuthorisationContainer context')
    }
    return context
}

const useAuthenticationOptional = (): AuthenticationState | undefined => {
    const context = useContext(AuthenticationContext)

    return context
}

const useAuthenticationRef = (): RefObject<string> => {
    const context = useContext(AuthenticationContext)
    if (context === undefined) {
        throw new Error('element is not in ApplicationContainer context')
    }
    const authRef = useRef<string>(context?.user?.user?.access_token ?? 'none')

    useEffect(() => {
        authRef.current = context?.user?.user?.access_token ?? 'none'
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [context])

    return authRef
}

export type {
    AuthenticationState,
}

export {
    AuthorisationContainer,
    useAuthentication,
    useAuthenticationOptional,
    useAuthenticationRef,
    hasRoles,
}
