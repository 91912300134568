import { DemoSidebarMenu } from "../components/SidebarMenu"
import { ModeContainer } from "../containers/ModeContainer"
import { DemoFileLayout } from "../layouts/DemoFileLayout"
import { DemoApplicationLayout } from "../layouts/FileApplicationLayout"
import { DemoSidebarLayout } from "../layouts/SidebarLayout"

import styles from './Layout.module.css'

const ScanSelect = () => {
    return <ModeContainer title={'scan selection'}>
        <DemoApplicationLayout>
            <DemoSidebarMenu
                key={'sidebarMenuLayout'}
            />
            <div
                key={'mainLayout'}
                className={styles.mainContainer}
            >
                <DemoSidebarLayout
                    key={'sidebarContent'}
                />
                <DemoFileLayout
                    key={'mainContent'}
                />
            </div>
        </DemoApplicationLayout>
    </ModeContainer>
}

export {
    ScanSelect,
}
