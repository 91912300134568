import React, { createContext, PropsWithChildren, useCallback, useContext, useEffect, useMemo, useRef } from 'react'
import { useMachine } from '@xstate/react'

import { useKeyHandler } from '../lib/useKeyHandler'

import remoteLayer from '../machines/layer'
import { loaders as dataLoaders } from '../machines/layer/loaders'

import { FileResult } from '../types'
import { useAuthenticationRef } from './AuthorisationContainer'
import { Helmet } from 'react-helmet'
import { ProgressBar } from '../components/ProgressBar'
import { fileMachine, FileState, } from '../machines/fileMachine'
import { useApplication } from './FileApplicationContainer'
import { ReportSchemaExt } from '../machines/fileApplicationMachine'
import ParticleSphere from '../components/ParticleSphere'
import { fileToTitle } from '../lib/files'
import { useMode } from './ModeContainer'

const FileContext = createContext<FileState | undefined>(undefined)

interface FileParameters {
    basePath: string
    file: FileResult
    tree: FileResult[]
    store?: string
    dataPath?: string
    role: 'user' | 'admin'
    mode: 'project' | 'file'
    handle?: FileSystemDirectoryHandle
    report?: ReportSchemaExt
    onSave?: (dataType: string, dataPayload: any) => void
}

const FileContainer: React.FC<PropsWithChildren<FileParameters>> = ({ children, dataPath, mode, role, onSave, file, tree, report, basePath }) => {
    const progressRef = useRef<HTMLProgressElement>(null)
    const labelRef = useRef<HTMLDivElement>(null)
    const infoRef = useRef<HTMLDivElement>(null)
    const { send: applicationSend } = useApplication()
    const roleMode = useMode()
    const authRef = useAuthenticationRef()
    const [{ value: currentState, context }, send] = useMachine(fileMachine.provide(remoteLayer(dataLoaders)).provide({
        actions: {
            emitSave: ({ event }) => {
                if (onSave === undefined) {
                    console.warn('[FileContainer]', 'onSave not provided')
                    return
                }
                onSave(event.payload.type, event.payload.data)
            },
            raiseLoaded: () => {
                applicationSend({
                    type: 'onDone'
                })
            },
            raiseError: ({ context }) => {
                applicationSend({
                    type: 'onError',
                    error: context.errors,
                })
            },
            raiseExitEditMode: () => {
                applicationSend({
                    type: 'exitEditMode',
                })
            },
            raiseEnterLockMode: () => {
                applicationSend({
                    type: 'enterLockMode',
                })
            },
            raiseSelectFile: ({ event }) => {
                applicationSend({
                    type: 'selectFile',
                    file: event?.output?.nextFile ?? event.file,
                })
            },
        }
    }), {
        input: {
            authRef,
            mode,
            role,
            protocol: file.protocol,
            basePath,
            dataPath,
            tree,
            file,
            report,
            adminMode: roleMode.mode === 'admin',
            refs: {
                progressRef,
                labelRef,
                infoRef,
            },
        }
    })

    const selectFile = useCallback((file: FileResult) => {
        if (context.customConclusions.length > 0) {
            return send({
                type: 'saveData',
                mode: 'conclusions',
                file: file,
            })
        }

        return send({
            type: 'selectFile',
            file,
        })
    }, [context, send])

    useKeyHandler((key, { ctrlKey }) => {
        if ('approvingFile' === currentState) {
            if (ctrlKey && key === 'A') {
                const idList = context.groundTruths
                    .filter(({ approval_status }) =>
                        !(['approved', 'revoked', 'rejected'].includes(approval_status)))
                    .map(({ id, threat_item_id }) =>
                        [id, threat_item_id])
                if (idList.length < 1) {
                    console.warn('nothing to approve')
                    return
                }
                // eslint-disable-next-line no-restricted-globals
                if (!confirm('Approve all ground truths?')) {
                    return
                }
                send({
                    type: 'approveAll',
                    status: 'approved',
                    idList,
                })
                return
            }
        }
        if ('viewingFile' === currentState) {
            if (ctrlKey && key === 'S') {
                send({
                    type: 'saveData',
                    mode: 'conclusions',
                })
                return
            }
            if (ctrlKey && ['d', 'f'].includes(key)) {
                if (key === 'd' && context.prevFile) {
                    selectFile(context.prevFile)
                    return
                } else if (key === 'f' && context.nextFile) {
                    selectFile(context.nextFile)
                    return
                }
            }
            
            if (mode!=='file' && ['Backspace', 'Clear'].includes(key)) {
                if (context.selection?.id) {
                    if (context.groundTruths.some(({ id }) =>
                        id === context.selection?.id)) {
                        // eslint-disable-next-line no-restricted-globals
                        return confirm('Revoke ground truth?') && send({
                            type: 'updateSelectionState',
                            status: 'revoked'
                        })
                    }
                    return send({
                        type: 'removeCustomConclusion',
                        id: context.selection.id
                    })
                }
                console.warn('no selection for delete')
                return
            }
            else if (['D'].includes(key)) {
                if (context.selection?.id) {
                    if (context.groundTruths.some(({ id }) =>
                        id === context.selection?.id)) {
                        // eslint-disable-next-line no-restricted-globals
                        return confirm('Duplicate ground truth?') && send({
                            type: 'duplicateGroundTruth',
                            status: 'revoked',
                        })
                    }
                }
                console.warn('no selection for duplicate')
                return
            }
            else if (key === 'ArrowUp') {
                return send({
                    type: 'nextSelection'
                })
            }
            else if (key === 'ArrowDown') {
                return send({
                    type: 'prevSelection'
                })
            }
        }

        if (key === 'Escape') {
            if (currentState.startsWith('viewing')) {
                if (context.selection !== undefined) {
                    return send({
                        type: 'setSelection'
                    })
                }
            }
        }
    })

    useEffect(() => {
        console.debug('[state]', 'fileMachine', currentState, context)
    }, [currentState, context])

    const scanLoaded = useMemo(() =>
        context.scanId && context?.data
        , [context])

    if (currentState === 'loadingFile') {
        return <div key={'fileContainer'}>
            <Helmet>
                <title>loading | {fileToTitle(context.protocol, context.file!, context?.store ?? context.basePath)}</title>
            </Helmet>
            <ParticleSphere
                className={''}
            />
            <ProgressBar
                visible={currentState === 'loadingFile'}
                progressRef={progressRef}
                labelRef={labelRef}
                infoRef={infoRef}
            />
        </div>
    }

    return <div key={'fileContainer'}>
        {scanLoaded && <FileContext.Provider
            key={'fileProvider'}
            value={{
                send,
                context,
                currentState,
            }}
            children={children}
        />}
    </div>
}

const useFile = (): FileState => {
    const context = useContext(FileContext)
    if (context === undefined) {
        throw new Error('element is not in FileContainer context')
    }
    return context
}

export {
    FileContainer,
    useFile,
}
