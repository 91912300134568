import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import useSWR from 'swr'
import styles from './tools/ToolPage.module.css'
import { AuthenticationState, useAuthentication } from '../containers/AuthorisationContainer'
import { config } from '../config'
import { ApprovalStatus } from '../../models'
import { ToolPage, ToolPageBody, ToolPageBodySection, ToolPageHeader, ToolPageHeaderButtons } from './tools/ToolPage'
import { useNavigate } from 'react-router-dom'
import { statusToColor } from '../lib/status'
import { Translate, useTranslate } from '../containers/I18nContainer'

const DATA_PAGE_SIZE = 10


const batchWorkFetcher = (getAuth: () => AuthenticationState) => ([status, page]: [string, number]) =>
    fetch(`${config.apiServer}/data/batchEx_${status}?page=${page}&size=${DATA_PAGE_SIZE}`, {
        headers: {
            'authorization': `Bearer ${getAuth()?.user?.user?.access_token ?? 'none'}`
        }
    }).then((res) => res.json()).then((res) => {
        if (!res.ok) {
            throw new Error('failed to retrieve batches')
        }
        return res?.data
    })

const doBatchDelete = (auth: AuthenticationState, batch_id: string) =>
    fetch(`${config.apiServer}/data/batch/${batch_id}`, {
        method: 'DELETE',
        headers: {
            'authorization': `Bearer ${auth?.user?.user?.access_token ?? 'none'}`
        },
    }).then((res) => res.json()).then((res) => {
        if (!res.ok) {
            throw new Error('failed to delete batches')
        }
        return res?.data
    })

const batchListFetcher = (getAuth: () => AuthenticationState) => ([page, query, dirty]: [number, string, number]) =>
    fetch(`${config.apiServer}/data/batch?page=${page}${query.length > 0 ? `&reference=${query.padEnd(8, '%')}`:''}&size=${DATA_PAGE_SIZE}`, {
        headers: {
            'authorization': `Bearer ${getAuth()?.user?.user?.access_token ?? 'none'}`
        }
    }).then((res) => res.json()).then((res) => {
        if (!res.ok) {
            throw new Error('failed to retrieve batches')
        }
        return res?.data
    })

interface BatchListingPageProps {
    status: ApprovalStatus | 'imported'
}

const BatchListingPage: FC<BatchListingPageProps> = ({ status }) => {
    const auth = useAuthentication()
    const [page, setPage] = useState(1)
    const { data: batchData, error, isLoading } = useSWR([status, page], batchWorkFetcher(() => auth))

    const { hasResults, lastPage, currentPage, batches } = useMemo(() => {
        if (batchData?.total === undefined || batchData?.results?.length === undefined) {
            return {
                hasResults: false,
                lastPage: 1,
                currentPage: 1,
                batches: [],
            }
        }
        return {
            hasResults: batchData.total > 0,
            lastPage: Math.ceil(batchData.total / batchData.size),
            currentPage: batchData.page,
            batches: batchData.results,
        }
    }, [batchData])

    if (isLoading && !hasResults) {
        return <div>loading...</div>
    }
    if (error) {
        return <div>Error loading batch data</div>
    }

    return <ToolPageBodySection empty={!isLoading && !hasResults}>
        <h3
            className={styles.sectionHeader}
            style={{
                borderBottomColor: statusToColor(status as ApprovalStatus) ?? '#000'
            }}
        ><Translate id={`status.${status}`} wrapper={false} /></h3>
        {hasResults
            ? <table className={styles.jobListTable}>
                <thead>
                    <tr>
                        <th><Translate id={'data.columns.reference'} /></th>
                        <th><Translate id={'data.columns.batch_id'} /></th>
                        <th><Translate id={'data.columns.categories'} /></th>
                        <th><Translate id={'data.columns.scans'} /></th>
                    </tr>
                </thead>
                <tbody>
                    {batches.map((batch: any) =>
                        <tr key={batch.id}>
                            <td className={styles.jobListCellReference}><a href={`/project/admin/${batch.reference}/${status}`}>{batch.reference}</a></td>
                            <td className={styles.jobListCellBatchId}>{batch.id}</td>
                            <td className={styles.jobListCellCategory}>{batch.categories.join(', ')}</td>
                            <td className={styles.jobListCellCount}>{batch.count}</td>
                        </tr>)}
                    {lastPage > 1 ? <tr>
                        <td colSpan={4}>
                            <div className={styles.jobListPager}>
                                {currentPage !== 1
                                    ? <div className={styles.jobListPagerButton} onClick={() => setPage((p) => Math.max(1, p - 1))}>&laquo; prev</div>
                                    : null}
                                {lastPage !== currentPage
                                    ? <div className={styles.jobListPagerButton} onClick={() => setPage((p) => Math.min(p + 1, lastPage))}>next &raquo;</div>
                                    : null}
                            </div>
                        </td>
                    </tr> : null}
                </tbody>
            </table>
            : <div style={{ fontWeight: 200 }}><Translate id={'general.none'} wrapper={false} /></div>}
    </ToolPageBodySection>
}

const BatchEditingPage: FC = () => {
    const auth = useAuthentication()
    const [page, setPage] = useState(1)
    const [dirty, setDirty] = useState(0)
    const [query, setQuery] = useState('')
    const [reference, setReference] = useState('')

    const { data: batchData, error, isLoading } = useSWR([page, reference, dirty], batchListFetcher(() => auth))
    const deleteBatch = useCallback(async (reference: string, batch_id: string) => {
        // eslint-disable-next-line no-restricted-globals
        if (confirm(`Are you sure you want to delete batch ${reference}?`)
            // eslint-disable-next-line no-restricted-globals
            && confirm(`Warning! You are about to delete all data for batch '${reference}'`)) {
                await doBatchDelete(auth, batch_id)
                setDirty((v)=>v+1)
        }
    }, [auth, setDirty])

    const { hasResults, lastPage, currentPage, batches } = useMemo(() => {
        if (batchData?.total === undefined || batchData?.results?.length === undefined) {
            return {
                hasResults: false,
                lastPage: 1,
                currentPage: 1,
                batches: [],
            }
        }
        return {
            hasResults: batchData.total > 0,
            lastPage: Math.ceil(batchData.total / batchData.size),
            currentPage: batchData.page,
            batches: batchData.results,
        }
    }, [batchData])

    if (isLoading && !hasResults) {
        return <div>loading...</div>
    }
    if (error) {
        return <div>Error loading batches</div>
    }

    return <ToolPageBodySection empty={!isLoading && !hasResults}>
        <div>
            <input
                type={'text'}
                placeholder={'batch reference...'}
                value={query}
                onKeyDown={(e)=> {
                    if (e.key === 'Enter') {
                        setReference(query)
                    }
                }}
                onChange={({target})=>
                    setQuery(target.value)}
            />
            <button
                onClick={()=>
                    setReference(query)}
            >search</button>
        </div>
        {hasResults
            ? <table className={styles.jobListTable}>
                <thead>
                    <tr>
                        <th><Translate id={'data.columns.reference'} /></th>
                        <th><Translate id={'data.columns.batch_id'} /></th>
                        <th><Translate id={'data.columns.scans'} /></th>
                        <th>&nbsp;</th>
                    </tr>
                </thead>
                <tbody>
                    {batches.map((batch: any) =>
                        <tr key={batch.id}>
                            <td className={styles.jobListCellReference}><a href={`/project/admin/${batch.reference}`}>{batch.reference}</a></td>
                            <td className={styles.jobListCellBatchId}>{batch.id}</td>
                            <td className={styles.jobListCellCount}>{batch.scan_count}</td>
                            <td className={styles.jobListCellAction}>
                                <button
                                    onClick={()=>
                                        deleteBatch(batch.reference, batch.id)}
                                >delete</button>
                            </td>
                        </tr>)}
                    {lastPage > 1 ? <tr>
                        <td colSpan={4}>
                            <div className={styles.jobListPager}>
                                {currentPage !== 1
                                    ? <div className={styles.jobListPagerButton} onClick={() => setPage((p) => Math.max(1, p - 1))}>&laquo; prev</div>
                                    : null}
                                {lastPage !== currentPage
                                    ? <div className={styles.jobListPagerButton} onClick={() => setPage((p) => Math.min(p + 1, lastPage))}>next &raquo;</div>
                                    : null}
                            </div>
                        </td>
                    </tr> : null}
                </tbody>
            </table>
            : <div style={{ fontWeight: 200 }}><Translate id={'general.none'} wrapper={false} /></div>}
    </ToolPageBodySection>
}

const statusList: ApprovalStatus[] = ['pending', 'approved', 'rejected', 'reserved', 'imported', 'revoked']

const AdminPage: FC = () => {
    const [scanMode, setScanMode] = useState(true)
    const nav = useNavigate()
    const title = useTranslate('toolPages.admin.title')
    const subTitle = useTranslate(scanMode ? 'general.scans' : 'general.batches')
    useEffect(() => {
        nav('/project/admin', { replace: true })
    }, [])

    return <ToolPage
        key={'adminPage'}
        title={title}
    >
        <ToolPageHeader>
            <h2>{title} | <span>{subTitle}</span></h2>
            <ToolPageHeaderButtons>
                <button
                    onClick={() => setScanMode((sm) => !sm)}
                >
                    {scanMode
                        ? <Translate id={'buttons.viewBatches'} wrapper={false} />
                        : <Translate id={'buttons.viewScans'} wrapper={false} />}
                </button>
            </ToolPageHeaderButtons>
        </ToolPageHeader>
        {scanMode
            ? <ToolPageBody>
                {statusList.map((status) =>
                    <BatchListingPage
                        key={`listBatches_${status}`}
                        status={status}
                    />)}
            </ToolPageBody>
            : <ToolPageBody>
                <BatchEditingPage />
            </ToolPageBody>}
    </ToolPage>
}

export {
    AdminPage,
}
