import { UserRoles } from "../types"

const AdminGroup: UserRoles[] = [
    UserRoles.Admin,
]

const DemoGroup: UserRoles[] = [
    UserRoles.Admin,
    UserRoles.DemoUser,
]

const ViewerGroup: UserRoles[] = [
    ...AdminGroup,
    UserRoles.LabellerAdmin,
    UserRoles.ModelTrainer,
]

const LabellerGroup: UserRoles[] = [
    ...ViewerGroup,
    UserRoles.Labeller,
]

const CommandForgeGroup: UserRoles[] = [
    UserRoles.CommandForgeUser,
    UserRoles.CommandForgeAdmin,
]

const AllGroups: UserRoles[] = Array.from(new Set([
    ...AdminGroup,
    ...ViewerGroup,
    ...LabellerGroup,
    ...CommandForgeGroup,
])) as UserRoles[]

export {
    LabellerGroup,
    ViewerGroup,
    AllGroups,
    AdminGroup,
    CommandForgeGroup,
    DemoGroup,
}
