import React, { FC, useMemo, useState } from 'react'
import { ModelViewerTools, ModelViewerControls, ModelViewerRenderer, ModelViewerContainer, RenderStyle, ToolbarSpacer, ToolbarGroup, ToolbarIcon, ModelViewerConfig, ScanItemResponseConclusion } from '@bbai-dartmouth/dartmouth-model-viewer-lib'
import styles from './ScanViewerLayout.module.css'
import { BsExclamationOctagonFill, BsListCheck, BsSave, BsSave2 } from 'react-icons/bs'
import { useScanConclusions } from '../lib/useScanConclusions'
import { Helmet } from 'react-helmet'
import { BoundingBoxWizard, DebugBoundingBoxes } from '../components/BoundingBoxWizard'
import { allLabels } from '../lib/labels'
import { useFile } from '../containers/FileContainer'
import { useApplication } from '../containers/FileApplicationContainer'
import { fileToTitle } from '../lib/files'

import { PagePrompt } from '../components/PagePrompt'
import { ThreatItems } from '../components/ThreatItems'
import { ReportFileControls } from '../components/ReportFileControls'
import { LoadingPane } from '../components/LoadingPane'
import { useNavigate } from 'react-router-dom'
import { ApprovalPane } from '../components/ApprovalPane'
import { HasRole } from '../components/HasRole'
import { ViewerGroup } from '../lib/groups'
import { Translate } from '../containers/I18nContainer'
import { GizmoHelper, GizmoViewcube, GizmoViewport } from '@react-three/drei'
import { groundTruthToConclusion } from '../machines/fileMachine'
import { InputGroup, SelectGroup } from '../components/SelectGroup'
import { WeakFileExtensionMatch } from '../machines/fileApplicationMachine'


interface FilePageActionsProps {
    selection?: ScanItemResponseConclusion
}
const FilePageActions: FC<FilePageActionsProps> = ({ selection }) => {
    const { context, send } = useFile()
    const [fit, setFit] = useState()
    const [match, setMatch] = useState()
    const [label, setLabel] = useState(selection?.label)
    const [overlap, setOverlap] = useState()

    const isComplete = useMemo(() => {
        return match && fit && overlap !== undefined
    }, [match, fit, overlap])

    const filename = useMemo(()=>
        context.file?.name.replace(WeakFileExtensionMatch, ''),[context.file])

    return <LoadingPane
        fullscreen
        key={'savingPane'}
        visible
    >
        <div
            style={{
                fontSize: '0.7em',
                marginBottom: '0.5em',
            }}
        >
            <div>
                <div>Filename</div>
                <InputGroup visible value={filename} />
            </div>
            <div>
                <div>Conclusion</div>
                <InputGroup visible value={context.selection?.id} />
            </div>
            <div>
                <div>Label</div>
                <InputGroup visible value={label} onChange={setLabel} />
            </div>
            <div>
                <div>Bounds Fit</div>
                <SelectGroup
                    key={'selectRenderStyle'}
                    visible
                    value={fit}
                    options={[
                        { id: 'large', label: 'too large' },
                        { id: 'small', label: 'too small' },
                        { id: 'ok', label: 'just right' },
                    ]}
                    onChange={setFit}
                />
            </div>
            <div>
                <div>Match</div>
                <SelectGroup
                    key={'selectRenderStyle'}
                    visible
                    value={match}
                    options={[
                        { id: 'true-positive', label: 'True Positive' },
                        { id: 'false-positive', label: 'False Positive' },
                        { id: 'false-negative', label: 'False Negative' },
                    ]}
                    onChange={setMatch}
                />
            </div>
            <div>
                <div>Overlap</div>
                <SelectGroup
                    key={'selectRenderStyle'}
                    visible
                    value={overlap}
                    options={[
                        { id: 0, label: 'none' },
                        { id: 1, label: '1' },
                        { id: 2, label: '2' },
                        { id: 3, label: '3' },
                        { id: 4, label: '4' },
                    ]}
                    onChange={setOverlap}
                />
            </div>
        </div>
        {isComplete
            ? <button onClick={() => send({
                type: 'addAnnotation',
                payload: {
                    overlap: overlap!,
                    fit: fit!,
                    filename: filename!,
                    match: match!,
                    label: label!,
                    conclusion_id: context.selection!.id!,
                },
            })}><Translate id={'buttons.saveAnnotation'} wrapper={false} /></button>
            : null}
        <button onClick={() => send({
            type: 'setSelection',
        })}><Translate id={'buttons.cancel'} wrapper={false} /></button>
    </LoadingPane>
}

interface PageActionsProps {
    selection?: ScanItemResponseConclusion
}

const PageActions: FC<PageActionsProps> = ({ selection }) => {
    const { send: applicationSend } = useApplication()
    const { context, currentState, send } = useFile()
    const nav = useNavigate()

    if (context.mode === 'file' && context.selection?.id) {
        return <FilePageActions selection={selection} />
    }

    if (currentState === 'fileUpdateError') {
        return <LoadingPane
            fullscreen
            key={'savingPane'}
            visible
        >
            <div>Failed To Update Scan</div>
            <button onClick={() => send({
                type: 'clearErrors',
            })}><Translate id={'buttons.close'} wrapper={false} /></button>
        </LoadingPane>
    } else if (currentState === 'fileSaveError') {
        return <LoadingPane
            fullscreen={false}
            key={'savingPane'}
            visible
        >
            <div>Failed To Save Data</div>
            <button
                onClick={() => send({
                    type: 'clearErrors',
                })}
            ><Translate id={'buttons.close'} wrapper={false} /></button>
        </LoadingPane>
    } else if (currentState === 'fileLoadError') {
        return <LoadingPane
            fullscreen={false}
            key={'savingPane'}
            visible
        >
            <div>Failed To Load Scan</div>
            <button
                onClick={() => send({
                    type: 'clearErrors',
                })}
            ><Translate id={'buttons.close'} wrapper={false} /></button>
        </LoadingPane>
    }
    else if (currentState === 'approvingFile') {
        return <ApprovalPane />
    }
    else if (currentState === 'completedFile') {
        if (context.nextFile === undefined) {
            return <LoadingPane
                fullscreen={true}
                key={'completePane'}
                visible
                onClickOut={() => {
                    if (context.role === 'admin') {
                        send({
                            type: 'closeOverlay',
                        })
                    }
                }}
            >
                <div>Batch Complete</div>
                <button
                    onClick={() => {
                        nav('/')
                    }}
                ><Translate id={'buttons.close'} wrapper={false} /></button>
                <button
                    onClick={() => {
                    }}
                ><Translate id={'buttons.reset'} wrapper={false} /></button>
            </LoadingPane>
        }

        return <LoadingPane
            key={'completePane'}
            visible
            onClickOut={() => {
                if (context.role === 'admin') {
                    send({
                        type: 'closeOverlay',
                    })
                }
            }}
        >
            <div>Scan Complete</div>
            {context.nextFile !== undefined && <button
                onClick={() => applicationSend({
                    type: 'selectFile',
                    file: context.nextFile!,
                })}
            ><Translate id={'buttons.next'} wrapper={false} /></button>}
            <HasRole any={ViewerGroup}>
                <button
                    onClick={() => applicationSend({
                        type: 'resetFile',
                    })}
                ><Translate id={'buttons.reset'} wrapper={false} /></button>
            </HasRole>
        </LoadingPane>
    }

    return <>
        <LoadingPane
            fullscreen={true}
            key={'savingPane'}
            visible={currentState === 'savingFile'}
        >Saving...</LoadingPane>
        <LoadingPane
            fullscreen={true}
            key={'updatingPane'}
            visible={currentState === 'updatingFile'}
        >Updating...</LoadingPane>
    </>

}

const mvConfig: ModelViewerConfig = {
    rescale: [1, 1, 1], //[context.data!.dimensions.width, context.data!.dimensions.height, context.data!.dimensions.depth],
    colors: {
        boundingBox: '#f00',
        customBoundingBox: '#000',
    },
    labelList: allLabels,
    editable: true,
}


const ScanViewerLayout: React.FC<{ debug?: boolean, viewRef?: React.LegacyRef<HTMLCanvasElement> }> = ({ debug, viewRef }) => {
    const { context, send, currentState } = useFile()
    const { context: applicationContext, send: applicationSend, currentState: applicationCurrentState } = useApplication()
    const { conclusions, customConclusions, selectedConclusion } = useScanConclusions(context?.results?.conclusions ?? [], context.customConclusions, context.groundTruths, context, context.selection)

    return <div
        key={'scanViewContent'}
        className={styles.scanViewContent}
    >
        <Helmet>
            <title>{fileToTitle(context.protocol, context.file!, context?.store ?? context.basePath)}</title>
        </Helmet>
        <ModelViewerContainer
            key={'modelContain'}
            id={context.scanId!}
            debug={debug}
            meta={{
                file: context.file!.path,
                path: context.path!,
            }}
            model={context.data!}
            selection={(currentState === 'editingFile' ? undefined : context.selection) ?? {}}
            group={[]}
            onLock={() => send({
                type: 'enterLockMode'
            })}
            onUnlock={() => send({
                type: 'exitEditMode'
            })}
        >
            <PageActions
                selection={selectedConclusion}
                key={'pageActions'}
            />
            {applicationContext.mode === 'project'
                ? <ThreatItems
                    autoHide={context.selection?.id === undefined && currentState !== 'approvingFile'}
                    visible={['viewingFile', 'completedFile', 'approvingFile'].includes(currentState)}
                    onSelect={(threatItemId) => send({
                        type: 'setThreatItem',
                        threat: context?.threats.find((threat) =>
                            threat.id === threatItemId),
                    })}
                    selectedThreatItemId={context.selectedThreat?.id}
                />
                : null}
            <ReportFileControls
                visible={['viewingFile', 'completedFile', 'approvingFile'].includes(currentState)}
                key={'reportControls'}
                threatCount={context.mode === 'file'
                    ? context.results?.conclusions.length
                    : context?.threats?.length}
                customConclusions={customConclusions ?? []}
                selection={selectedConclusion}
            >
                {currentState === 'viewingFile' && context.customConclusions.length > 0
                    ? <BsSave2
                        title={'Save'}
                        onClick={() => send({
                            type: 'saveData',
                            mode: 'conclusions',
                        })}
                    />
                    : null}
                {currentState === 'approvingFile' && context.groundTruths.length > 0
                    ? <BsListCheck
                        title={'Approve All'}
                        onClick={() => send({
                            type: 'approveAll',
                            status: 'approved',
                            idList: context.groundTruths
                                .filter(({ approval_status }) =>
                                    !(['approved', 'revoked', 'rejected'].includes(approval_status)))
                                .map(({ id, threat_item_id }) =>
                                    [id, threat_item_id]),
                        })}
                    />
                    : null}
            </ReportFileControls>
            <ModelViewerTools
                options={{
                    renderStyles: [RenderStyle.Iso, RenderStyle.Mip],
                }}
                key={'mvTools'}
            />
            <ModelViewerRenderer
                key={'mvPane'}
                controls
                onUpdateConclusion={(id, scanId, data) =>
                    send({
                        type: 'updateConclusion',
                        id,
                        scanId,
                        data,
                    })}
                onPointerMissed={selectedConclusion
                    ? (() => send({
                        type: 'setSelection'
                    }))
                    : undefined}
                onSelectConclusion={(id, scanId, mode) => {
                    if (mode === 0) {
                        return send({
                            type: 'setSelection',
                            id,
                            scanId,
                        })
                    } else if (mode === 1) {
                        return send({
                            type: 'removeCustomConclusion',
                            id,
                            scanId,
                        })
                    }
                }}
                conclusions={conclusions ?? []}
                customConclusions={customConclusions}
                style={{
                    position: 'absolute',
                    inset: 0,
                    userSelect: 'none',
                }}
                canvasRef={viewRef}
                config={mvConfig}
            >
                <GizmoHelper
                    alignment={'bottom-right'}
                    margin={[100, 50]}
                >
                    <group scale={0.7}>
                        <GizmoViewcube />
                    </group>
                </GizmoHelper>
                {currentState !== 'editingFile'
                    ? <DebugBoundingBoxes
                        scanId={context.scanId!}
                        color={'#f0f'}
                        groundTruths={context.groundTruths}
                        conclusions={debug
                            ? customConclusions
                            : []}
                        selection={context.selection}
                        onUpdate={(id, scanId, data) =>
                            send({
                                type: 'updateConclusion',
                                id,
                                scanId,
                                data,
                            })}
                        onSelect={(id, scanId, mode) => {
                            if (mode === 0) {
                                return send({
                                    type: 'setSelection',
                                    id,
                                    scanId,
                                })
                            } else if (mode === 1) {
                                return send({
                                    type: 'removeCustomConclusion',
                                    id,
                                    scanId,
                                })
                            }
                        }}
                    /> : null}
                {currentState === 'editingFile'
                    ? <BoundingBoxWizard
                        key={'boundingWizard'}
                        category={context.selectedThreat?.item_id}
                        onCancel={() => {
                            send({
                                type: 'toggleEditMode'
                            })
                        }}
                        onComplete={({ id, label, userLabel, boundingBox, globalBoundingBox, orientation }) =>
                            send({
                                type: 'createCustomConclusion',
                                data: {
                                    id,
                                    scanId: context.scanId!,
                                    threatItemId: context.selectedThreat?.id ?? null,
                                    boundingBox,
                                    globalBoundingBox,
                                    probability: 1,
                                    label: label,
                                    category: userLabel,
                                    isBelowThreshold: false,
                                    inferences2D: [],
                                    runtimeThreshold: 0,
                                    custom: true,
                                    orientation,
                                }
                            })
                        }
                    /> : null}
            </ModelViewerRenderer>
            {currentState !== 'completedFile'
                ? <ModelViewerControls
                    autoHide={false}
                    key={'mvControls'}
                    requestHd={() => {
                        console.log('request hd')
                    }}
                    fullscreen={applicationContext.view.fullscreen}
                    toggleFullscreen={() => applicationSend({
                        type: 'toggleFullscreen'
                    })}
                >
                    <ToolbarSpacer />
                    <ToolbarGroup>
                        <ToolbarIcon
                            element={BsExclamationOctagonFill}
                            label={'Add ground truth'}
                            onClick={() => send({
                                type: 'toggleEditMode'
                            })}
                        />
                    </ToolbarGroup>
                </ModelViewerControls>
                : null}
        </ModelViewerContainer>
    </div>
}

export {
    ScanViewerLayout,
}
