import React from "react";
import {
    FaSpinner,
    FaCheckCircle,
    FaHourglassStart,
    FaTimesCircle,
    FaQuestionCircle,
} from "react-icons/fa";
import { BiSolidNoEntry } from "react-icons/bi";
import { MdOutlinePending } from "react-icons/md";

import styles from './StatusIcon.module.css';

const getStatusIcon = (status: string): JSX.Element => {
    switch (status) {
        case "Pending":
            return <MdOutlinePending className={`${styles.statusIcon} ${styles.pending}`} title={"Pending"}/>;
        case "Queued":
            return <FaHourglassStart className={`${styles.statusIcon} ${styles.queued}`} title={"Queued"}/>;
        case "InProgress":
            return <FaSpinner className={`${styles.statusIcon} ${styles.inprogress} ${styles.animated}`} title={"In Progress"}/>;
        case "Completed":
            return <FaCheckCircle className={`${styles.statusIcon} ${styles.completed}`} title={"Completed"}/>;
        case "Failed":
            return <FaTimesCircle className={`${styles.statusIcon} ${styles.failed}`} title={"Failed"}/>;
        case "Cancelled":
            return <BiSolidNoEntry className={`${styles.statusIcon} ${styles.cancelled}`} title={"Cancelled"}/>;
        default:
            return <FaQuestionCircle className={`${styles.statusIcon} ${styles.unknown}`} title={"Unknown"}/>;
    }
};
export default getStatusIcon;
