import React, { createContext, PropsWithChildren, useContext, useEffect, useState } from 'react'
import { useMachine } from '@xstate/react'
import { applicationMachine, ApplicationState, ProjectSelection, SidebarMode } from '../machines/fileApplicationMachine'

import { AppStateStorage, loadApplicationState, saveApplicationState } from '../lib/storage'
import { useKeyHandler } from '../lib/useKeyHandler'

import remoteLayer from '../machines/layer'
import { loaders as dataLoaders } from '../machines/layer/loaders'

import { useParams } from 'react-router-dom'
import { getUserRoles, useAuthentication, useAuthenticationRef } from './AuthorisationContainer'
import { Helmet } from 'react-helmet'
import { UserRoles } from '../types'

const ApplicationContext = createContext<ApplicationState | undefined>(undefined)
interface FileApplicationContainerProps {

}
const FileApplicationContainer: React.FC<PropsWithChildren<FileApplicationContainerProps>> = ({ children }) => {
    const auth = useAuthentication()
    const authRef = useAuthenticationRef()
    const [initialState] = useState(loadApplicationState())
    const params = useParams<{ protocol: any }>()

    const [{ value: currentState, context }, send] = useMachine(applicationMachine.provide(remoteLayer(dataLoaders)), {
        input: initialState.protocol !== undefined && initialState?.protocol === params.protocol ? {
            authRef,
            admin: getUserRoles(auth).includes(UserRoles.Admin),
            mode: 'file',
            protocol: params.protocol,
            ...initialState,
            file: initialState.file!,
            store: initialState.store,
            project: undefined,
        } : {
            authRef,
            admin: getUserRoles(auth).includes(UserRoles.Admin),
            mode: 'file',
            protocol: params.protocol,
            file: undefined,
            basePath: undefined,
            project: undefined,
        }
    })

    useKeyHandler((key, { ctrlKey }) => {
        // if (key === 'Escape') {
        //     if (ctrlKey) {
        //         return send({
        //             type: 'shortcutEscapeHard'
        //         })
        //     }
        //     return send({
        //         type: 'shortcutEscapeHard'
        //     })
        // } else if (ctrlKey && key === 'e') {
        //     return send({
        //         type: 'shortcutEdit'
        //     })
        // } else if (['Backspace', 'Clear'].includes(key) && !ctrlKey) {
        //     return send({
        //         type: 'shortcutDelete'
        //     })
        // } else if (key === 'd' && !ctrlKey) {
        //     return send({
        //         type: 'shortCutPrevious'
        //     })
        // } else if (key === 'f' && !ctrlKey) {
        //     return send({
        //         type: 'shortCutNext'
        //     })
        // }
        // else if (key === 'ArrowUp') {
        //     return send({
        //         type: 'shortcutUp'
        //     })
        // } else if (key === 'ArrowDown') {
        //     return send({
        //         type: 'shortcutDown'
        //     })
        // } else if (key === 'ArrowLeft') {
        //     return send({
        //         type: 'shortcutLeft'
        //     })
        // } else if (key === 'ArrowRight') {
        //     return send({
        //         type: 'shortcutRight'
        //     })
        // }
        if (ctrlKey && key === 'D') {
            return send({
                type: 'toggleDebug'
            })
        }
        if ('viewingFile' === currentState) {
            if (ctrlKey && key === 'p') {
                return send({
                    type: 'dataPathSelect'
                })
            }
        }
        if ('lockedFile' === currentState) {
            return
        }

        if (key === 'Escape') {
            if (currentState.startsWith('viewing')) {
                if (context.view.fullscreen) {
                    return send({
                        type: 'toggleFullscreen'
                    })
                }
                else if (currentState.startsWith('viewing') && context.view.sidebar !== SidebarMode.None) {
                    return send({
                        type: 'hideViewSidebar'
                    })
                }
            }
        }
    })

    useEffect(() => {
        console.debug('[state]', 'fileApplicationMachine:file', currentState, context)
        if (!currentState.endsWith('Error') && !currentState.startsWith('loading')) {
            saveApplicationState(context.basePath, context.file, context.protocol, context.store)
        }
    }, [currentState, context])

    return <div>
        <Helmet>
            <title>{context.path ? `browsing | ${context.path}`: 'browsing'} </title>
        </Helmet>
        <ApplicationContext.Provider
            value={{
                send,
                context,
                currentState,
            }}
            children={children}
        />
    </div>
}

interface DemoApplicationContainerProps {
    initialState: AppStateStorage
}
const DemoApplicationContainer: React.FC<PropsWithChildren<DemoApplicationContainerProps>> = ({ children, initialState }) => {
    const auth = useAuthentication()
    const authRef = useAuthenticationRef()

    const [{ value: currentState, context }, send] = useMachine(applicationMachine.provide(remoteLayer(dataLoaders)), {
        input: {
            authRef,
            admin: getUserRoles(auth).includes(UserRoles.Admin),
            mode: 'file',
            protocol: 's3',
            ...initialState,
            file: initialState.file!,
            store: initialState.store,
            project: undefined,
        }
    })

    useKeyHandler((key, { ctrlKey }) => {
        if ('lockedFile' === currentState) {
            return
        }

        if (key === 'Escape') {
            if (currentState.startsWith('viewing')) {
                if (context.view.fullscreen) {
                    return send({
                        type: 'toggleFullscreen'
                    })
                }
                else if (currentState.startsWith('viewing') && context.view.sidebar !== SidebarMode.None) {
                    return send({
                        type: 'hideViewSidebar'
                    })
                }
            }
        }
    })

    useEffect(() => {
        console.debug('[state]', 'fileApplicationMachine:demo', currentState, context)
        if (!currentState.endsWith('Error') && !currentState.startsWith('loading')) {
            saveApplicationState(context.basePath, context.file, context.protocol, context.store)
        }
    }, [currentState, context])

    return <div>
        <Helmet>
            <title>scan selection</title>
        </Helmet>
        <ApplicationContext.Provider
            value={{
                send,
                context,
                currentState,
            }}
            children={children}
        />
    </div>
}


const ProjectApplicationContainer: React.FC<PropsWithChildren<{ selection: ProjectSelection }>> = ({ selection, children }) => {
    const auth = useAuthentication()
    const authRef = useAuthenticationRef()
    const [{ value: currentState, context }, send] = useMachine(applicationMachine.provide(remoteLayer(dataLoaders)), {
        input: {
            authRef,
            admin: getUserRoles(auth).includes(UserRoles.Admin),
            mode: 'project',
            protocol: 'api' as any,
            project: selection,
        }
    })

    useKeyHandler((key, { ctrlKey }) => {
        if (ctrlKey && key === 'D') {
            return send({
                type: 'toggleDebug'
            })
        }
        if ('lockedFile' === currentState) {
            return
        }
        if ('viewingFile' === currentState) {
            if (ctrlKey && key === 'p') {
                return send({
                    type: 'dataPathSelect'
                })
            }
        }

        if (key === 'Escape') {
            if (currentState.startsWith('viewing')) {
                if (context.view.fullscreen) {
                    return send({
                        type: 'toggleFullscreen'
                    })
                }
                else if (currentState.startsWith('viewing') && context.view.sidebar !== SidebarMode.None) {
                    return send({
                        type: 'hideViewSidebar'
                    })
                }
            }
        }
    })

    useEffect(() => {
        console.debug('[state]', 'fileApplicationMachine:project', currentState, context)
    }, [currentState, context])

    return <div>
        <Helmet>
            <title>{context.path ? `browsing | ${context.path}`: 'browsing'} </title>
        </Helmet>
        <ApplicationContext.Provider
            value={{
                send,
                context,
                currentState,
            }}
            children={children}
        />
    </div>
}

const useApplication = (): ApplicationState => {
    const context = useContext(ApplicationContext)
    if (context === undefined) {
        throw new Error('element is not in ApplicationContainer context')
    }
    return context
}

export {
    FileApplicationContainer,
    ProjectApplicationContainer,
    DemoApplicationContainer,
    useApplication,
}
