import React, { FC, useMemo } from 'react'
import { NothingSelected } from '../components/NothingSelected'
import { Box } from '../components/Box'
import styles from './MainFileLayout.module.css'
import { useApplication } from '../containers/FileApplicationContainer'
import { FileContainer } from '../containers/FileContainer'
import { Translate } from '../containers/I18nContainer'
import { DemoLayout } from './DemoLayout'

const PROMPT_MESSAGE_MAP: Record<'file' | 'project', Record<string, string>> = {
    project: {
        projectSelect: 'Nothing Selected',
        projectEmpty: 'Nothing Available',
        projectLoadError: 'Failed To Load Batch',
    },
    file: {
        fileLoadError: 'Error Loading File',
        pathLoadError: 'Error Loading Directory',
    }
}

const hasPromptView = (currentState: string, mode: 'project' | 'file'): boolean =>
    PROMPT_MESSAGE_MAP[mode]?.[currentState] !== undefined

interface PromptViewProps {
    currentState: string
    mode: 'project' | 'file'
}

const PromptView: FC<PromptViewProps> = ({ currentState, mode }) => {
    if (!hasPromptView(currentState, mode)) {
        return null
    }

    return <div
        key={'fullLayout'}
        className={styles.fullLayout}
    >
        <Box key={'scanView'} fill flex center>{PROMPT_MESSAGE_MAP[mode][currentState]}</Box>
        {mode === 'project'
            ? <NothingSelected
                prompt
            />
            : null}
    </div>
}

const DemoFileLayout = ({ viewRef }: { viewRef?: React.LegacyRef<HTMLCanvasElement> }) => {
    const { context, currentState } = useApplication()
    const fileSelected = useMemo(() =>
        context?.tree && context?.path && context?.file
        , [context])

    if (hasPromptView(currentState, context.mode)) {
        return <PromptView
            currentState={currentState}
            mode={context.mode}
        />
    }
    if (currentState === 'loadingPath') {
        return <div
            key={'fullLayout'}
            className={styles.fullLayout}
        >
            <Box key={'scanView'} fill flex center>
                <Translate
                    id={'user.loadingScans'}
                />
            </Box>
        </div>
    }

    if (!fileSelected) {
        return <div
            key={'fullLayout'}
            className={styles.fullLayout}
        >
            <NothingSelected
                prompt={false}
            />
        </div>
    }

    return <div
        key={'fullLayout'}
        className={styles.fullLayout}
    >
        <FileContainer
            key={context.fileId}
            dataPath={context.dataPath}
            mode={context.mode}
            role={context.admin ? 'admin' : 'user'}
            file={context.file!}
            tree={context.tree!}
            basePath={context.basePath!}
            store={context.store}
            report={context.report}
        >
            <DemoLayout
                debug={false}
                viewRef={viewRef}
            />
        </FileContainer>
    </div>
}

export {
    DemoFileLayout,
}
