import { HasRole, RoleLink } from "../components/HasRole"
import { Translate } from "../containers/I18nContainer"
import { LabellerGroup, AdminGroup, CommandForgeGroup, DemoGroup } from "../lib/groups"
import { UserRoles } from "../types"
import styles from './IndexPage.module.css'

const ClearCacheButton = () =>
    <button
        id={'clearStoreButton'}
        onClick={() => {
            localStorage.clear()
        }}
    >reset cache</button>

const IndexPage = () =>
    <div className={styles.pageWrapper}>
        <div className={styles.storageLayout}>
            <div className={styles.storageLinkContainer}>
                <RoleLink
                    not={UserRoles.DemoUser}
                    any={LabellerGroup}
                    to={'/project'}
                >
                    <div className={styles.storageLink}><Translate id={'pages.bootcamp.title'} /></div>
                </RoleLink>
                <RoleLink
                    any={AdminGroup}
                    to={'/files/local'}
                >
                    <div className={styles.storageLink}><Translate id={'pages.browseLocal.title'} /></div>
                </RoleLink>
                <RoleLink
                    any={AdminGroup}
                    to={'/files/s3'}
                >
                    <div className={styles.storageLink}><Translate id={'pages.browseS3.title'} /></div>
                </RoleLink>
                <RoleLink
                    any={AdminGroup}
                    to={'/project/admin'}
                >
                    <div className={styles.storageLink}><Translate id={'pages.administration.title'} /></div>
                </RoleLink>
                <RoleLink
                    any={AdminGroup}
                    to={'/data/scan'}
                >
                    <div className={styles.storageLink}><Translate id={'pages.apiBrowser.title'} /></div>
                </RoleLink>
                <RoleLink
                    any={AdminGroup}
                    to={'/status'}
                >
                    <div className={styles.storageLink}><Translate id={'pages.systemStatus.title'} /></div>
                </RoleLink>
                <RoleLink
                    any={CommandForgeGroup}
                    to={'/command-forge'}
                >
                    <div className={styles.storageLink}><Translate id={'pages.commandForge.title'} /></div>
                </RoleLink>
                <RoleLink
                    any={DemoGroup}
                    to={'/pte'}
                >
                    <div className={styles.storageLink}><Translate id={'pages.pte.title'} /></div>
                </RoleLink>
            </div>
            <HasRole any={AdminGroup}>
                <div style={{
                    position: 'fixed',
                    right: '1em',
                    bottom: '1em',
                }}>
                    <ClearCacheButton />
                </div>
            </HasRole>
        </div>
    </div>

export {
    IndexPage,
}
