import React, { useState } from "react";

import styles from "./TaskForm.module.css";
import { useAuthenticationRef } from "../../containers/AuthorisationContainer";
import { config } from "../../config";
import { getAuthHeaders } from "../../machines/layer/loaders";
import { FaRegWindowClose } from "react-icons/fa";

type Field = {
    id: string;
    label: string;
    type: "text" | "number" | "select" | "multi-select";
    mandatory: boolean;
    options?: { label: string; value: string }[];
    defaultValue?: string | string[];
};

const masterFieldList: Field[] = [
    { id: "source", label: "Source", type: "text", mandatory: true },
    { id: "destination", label: "Destination", type: "text", mandatory: true },
    {
        id: "palettes",
        label: "Palettes",
        type: "multi-select",
        mandatory: false,
        options: [{ label: "blue-red", value: "blue-red" }, { label: "plastic", value: "plastic" }],
        defaultValue: ["blue-red", "plastic"]
    },
    {
        id: "renderer",
        label: "Renderer",
        type: "select",
        mandatory: false,
        options: [{ label: "Eagle", value: "Eagle" }, { label: "Eagle with Ground Truths", value: "EagleWithGroundTruths" },],
        defaultValue: "EagleWithGroundTruths"
    },
    { id: "viewCounts", label: "View Counts", type: "text", mandatory: false, defaultValue: "6,25" },
    {
        id: "compressionLevel",
        label: "Compression Level",
        type: "select",
        mandatory: false,
        options: [
            { label: "optimal", value: "optimal" },
            { label: "none", value: "none" },
            { label: "fastest", value: "fastest" },
            { label: "slowest", value: "slowest" }
        ],
        defaultValue: "optimal"
    },
    {
        id: "scaleFactors",
        label: "Scale Factors",
        type: "multi-select",
        mandatory: false,
        options: [{ label: "1", value: "1" }, { label: "2", value: "2" }, { label: "3", value: "3" }],
        defaultValue: ["1", "2"]
    },
    {
        id: "interpolation",
        label: "Interpolation",
        type: "select",
        mandatory: false,
        options: [{ label: "Lanczos", value: "Lanczos" }, { label: "Trilinear", value: "Trilinear" }],
        defaultValue: "Lanczos",
    },
    { id: "plasticDestination", label: "Plastic Destination", type: "text", mandatory: false },
    { id: "blueRedDestination", label: "Blue-Red Destination", type: "text", mandatory: false },
    { id: "includePath", label: "Include Path", type: "text", mandatory: false },
    { id: "excludePath", label: "Exclude Path", type: "text", mandatory: false },
    { id: "inputList", label: "Input List", type: "text", mandatory: true },
];

interface TaskFieldMapping {
    [taskType: string]: string[];
}

const taskFieldMapping: Record<string, { tasks: TaskFieldMapping }> = {
    "Generate Tasks": {
        tasks: {
            "generate-analogic-to-dcs-tasks": ["source", "destination", "includePath", "excludePath"],
            "generate-dcs-to-drt-tasks": ["source", "destination", "compressionLevel", "scaleFactors", "interpolation", "includePath", "excludePath"],
            "generate-dcs-to-image-tasks": ["source", "destination", "renderer", "palettes", "viewCounts", "includePath", "excludePath"],
            "generate-extract-from-tarball-tasks": ["source", "destination", "plasticDestination", "blueRedDestination", "includePath", "excludePath"],
            "generate-hif-to-dcs-tasks": ["source", "destination", "includePath", "excludePath"],
            "generate-render-bounding-boxes-tasks": ["source", "destination", "includePath", "excludePath"],
            "generate-update-2d-gt-tasks": ["source", "destination", "includePath", "excludePath"],
        },
    },
    "Conversion Tasks": {
        tasks: {
            "analogic-to-dcs": ["source", "destination"],
            "dcs-to-drt": ["source", "destination", "compressionLevel", "scaleFactors", "interpolation"],
            "dcs-to-image": ["source", "destination", "renderer", "palettes", "viewCounts"],
            "export-3d-ground-truths": ["source", "destination"],
            "extract-from-tarball": ["source", "destination", "plasticDestination", "blueRedDestination"],
            "extract-ground-truths": ["source", "destination"],
            "hif-to-dcs": ["source", "destination"],
            "render-bounding-boxes": ["source", "destination"],
            "update-2d-gt": ["source", "destination"],
        },
    },
    "Utility Tasks": {
        tasks: {
            "count-files": ["source", "destination", "includePath"],
            "list-files": ["source", "destination", "includePath", "excludePath"],
            "file-command": ["inputList"],
        },
    },
};

type TaskFormProps = {
    onClose: () => void;
};

const TaskForm: React.FC<TaskFormProps> = ({ onClose }) => {
    const [selectedTaskType, setSelectedTaskType] = useState<string>("");
    const [formData, setFormData] = useState<Record<string, string | string[]>>({});
    const [message, setMessage] = useState<{ type: "success" | "error"; text: string } | null>(null);

    const authRef = useAuthenticationRef();

    const handleTaskTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const taskType = e.target.value;
        setSelectedTaskType(taskType);
        setFormData({});
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>, fieldType?: "multi-select") => {
        const { name, value } = e.target;

        if (fieldType === "multi-select") {
            const selectedValues = Array.from((e.target as HTMLSelectElement).selectedOptions).map((option) => option.value);
            setFormData((prev) => ({ ...prev, [name]: selectedValues }));
        } else {
            setFormData((prev) => ({ ...prev, [name]: value }));
        }
    };

    const applicableFields = selectedTaskType
        ? Object.values(taskFieldMapping).find((group) =>
        group.tasks.hasOwnProperty(selectedTaskType)
    )?.tasks[selectedTaskType].map((fieldId) =>
        masterFieldList.find((field) => field.id === fieldId)
    ) || []
        : [];

    const generatedPayload = Object.entries(formData).reduce((acc, [key, value]) => {
        if (Array.isArray(value)) {
            acc[key] = value.join(',');
        } else {
            acc[key] = value;
        }

        return acc;
    }, {} as Record<string, string>);

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        try {
            console.log("[commandForge]", { generatedPayload });
            const response = await fetch(`${config.schedulerApi}/tasks`, {
                method: "POST",
                ...getAuthHeaders(authRef.current),
                body: JSON.stringify({ type: selectedTaskType, payloads: [generatedPayload] }),
            });

            if (!response.ok) {
                throw new Error("Error submitting task");
            }

            setMessage({ type: "success", text: "Task submitted successfully" });
        } catch (err: any) {
            setMessage({ type: "error", text: err.message || "Something went wrong" });
        }
    };

    return (
        <form onSubmit={handleSubmit} className={styles.formContainer}>
            <div className={styles.formGroup}>
                <label htmlFor="taskType" className={styles.label}>
                    Task Type:
                </label>
                <select
                    onChange={handleTaskTypeChange}
                    className={styles.select}
                    value={selectedTaskType || ""}
                >
                    <option value="">-- select --</option>
                    {Object.keys(taskFieldMapping).map((group) => (
                        <optgroup key={group} label={group}>
                            {Object.keys(taskFieldMapping[group].tasks).map((taskType) => (
                                <option key={taskType} value={taskType}>
                                    {taskType}
                                </option>
                            ))}
                        </optgroup>
                    ))}
                </select>
            </div>

            {applicableFields.length > 0 &&
                applicableFields.map((field) => {
                    if (!field) return null;

                    if (!formData[field.id] && field.defaultValue !== undefined) {
                        setFormData((prev) => ({
                            ...prev,
                            [field.id]: Array.isArray(field.defaultValue)
                                ? [...field.defaultValue]
                                : (field.defaultValue as string | string[]),
                        }));
                    }

                    return (
                        <div key={field.id} className={styles.formGroup}>
                            <label className={styles.label}>
                                {field.label}
                                {field.mandatory && <span className={styles.mandatory}> *</span>}
                            </label>

                            {(field.type === "text" || field.type === "number") && (
                                <input
                                    id={field.id}
                                    name={field.id}
                                    type={field.type}
                                    value={formData[field.id] as string || ""}
                                    onChange={handleInputChange}
                                    className={styles.input}
                                    required={field.mandatory}
                                />
                            )}

                            {field.type === "select" && field.options && (
                                <div className={styles.radioGroup}>
                                    {field.options.map((option) => (
                                        <div key={option.value} className={styles.radioItem}>
                                            <input
                                                type="radio"
                                                id={`${field.id}-${option.value}`}
                                                name={field.id}
                                                value={option.value}
                                                checked={formData[field.id] === option.value}
                                                onChange={handleInputChange}
                                                className={styles.radioButton}
                                                required={field.mandatory}
                                            />
                                            <label
                                                htmlFor={`${field.id}-${option.value}`}
                                                className={styles.radioLabel}
                                            >
                                                {option.label}
                                            </label>
                                        </div>
                                    ))}
                                </div>
                            )}

                            {field.type === "multi-select" && field.options && (
                                <div className={styles.checkboxGroup}>
                                    {field.options.map((option) => (
                                        <div key={option.value} className={styles.checkboxItem}>
                                            <input
                                                type="checkbox"
                                                id={`${field.id}-${option.value}`}
                                                name={field.id}
                                                value={option.value}
                                                checked={(formData[field.id] as string[] || []).includes(option.value)}
                                                onChange={(e) => {
                                                    const selectedValues = new Set(formData[field.id] as string[]);
                                                    if (e.target.checked) {
                                                        selectedValues.add(e.target.value);
                                                    } else {
                                                        selectedValues.delete(e.target.value);
                                                    }
                                                    setFormData((prev) => ({
                                                        ...prev,
                                                        [field.id]: Array.from(selectedValues),
                                                    }));
                                                }}
                                                className={styles.checkbox}
                                                required={field.mandatory}
                                            />
                                            <label
                                                htmlFor={`${field.id}-${option.value}`}
                                                className={styles.checkboxLabel}
                                            >
                                                {option.label}
                                            </label>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    );
                })}

            <div className={styles.formActions}>
                <button
                    type="button"
                    className={styles.cancelButton}
                    onClick={onClose}
                >
                    Cancel
                </button>

                <button type="submit" className={styles.submitButton}>
                    Submit
                </button>
            </div>


            {message && (
                <div className={message.type === "success" ? styles.successMessage : styles.errorMessage}>
                    <button
                        className={styles.closeButton}
                        onClick={() => setMessage(null)}
                        aria-label="Close message"
                    >
                        <FaRegWindowClose size={16} />
                    </button>

                    {message.text}
                </div>
            )}
        </form>
    );
};

export default TaskForm;
