import React from 'react'
import { ModelViewerTools, ModelViewerControls, ModelViewerRenderer, ModelViewerContainer, RenderStyle, ModelViewerConfig } from '@bbai-dartmouth/dartmouth-model-viewer-lib'
import styles from './ScanViewerLayout.module.css'
import { useScanConclusions } from '../lib/useScanConclusions'
import { Helmet } from 'react-helmet'
import { allLabels } from '../lib/labels'
import { useFile } from '../containers/FileContainer'
import { useApplication } from '../containers/FileApplicationContainer'
import { fileToTitle } from '../lib/files'

import { LoadingPane } from '../components/LoadingPane'
import { useNavigate } from 'react-router-dom'
import { ApprovalPane } from '../components/ApprovalPane'
import { HasRole } from '../components/HasRole'
import { ViewerGroup } from '../lib/groups'
import { Translate } from '../containers/I18nContainer'
import { GizmoHelper, GizmoViewcube } from '@react-three/drei'
import { DemoFileControls } from '../components/DemoFileControls'
import { WeakFileExtensionMatch } from '../machines/fileApplicationMachine'


const EMULATOR_API = 'https://emu-0.demo.dartmouth-demo.cloud/api'
const EMULATOR_API_KEY = 'dev-key'

const triggerScan = async (filename: string) => {
    const file = filename.replace(WeakFileExtensionMatch, '.dcs')
    console.debug('[triggerScan]', 'file', file)

    return fetch(`${EMULATOR_API}/scan`,{
        method: 'POST',
        headers: {
            'x-api-key': EMULATOR_API_KEY,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            file,
        })
    })
}

const PageActions = () => {
    const { send: applicationSend } = useApplication()
    const { context, currentState, send } = useFile()
    const nav = useNavigate()

    if (currentState === 'fileUpdateError') {
        return <LoadingPane
            fullscreen={true}
            key={'savingPane'}
            visible
        >
            <div>Failed To Update Scan</div>
            <button onClick={() => send({
                type: 'clearErrors',
            })}><Translate id={'buttons.close'} wrapper={false} /></button>
        </LoadingPane>
    } else if (currentState === 'fileSaveError') {
        return <LoadingPane
            fullscreen={false}
            key={'savingPane'}
            visible
        >
            <div>Failed To Save Data</div>
            <button
                onClick={() => send({
                    type: 'clearErrors',
                })}
            ><Translate id={'buttons.close'} wrapper={false} /></button>
        </LoadingPane>
    } else if (currentState === 'fileLoadError') {
        return <LoadingPane
            fullscreen={false}
            key={'savingPane'}
            visible
        >
            <div>Failed To Load Scan</div>
            <button
                onClick={() => send({
                    type: 'clearErrors',
                })}
            ><Translate id={'buttons.close'} wrapper={false} /></button>
        </LoadingPane>
    }
    else if (currentState === 'approvingFile') {
        return <ApprovalPane />
    }
    else if (currentState === 'completedFile') {
        if (context.nextFile === undefined) {
            return <LoadingPane
                fullscreen={true}
                key={'completePane'}
                visible
                onClickOut={() => {
                    if (context.role === 'admin') {
                        send({
                            type: 'closeOverlay',
                        })
                    }
                }}
            >
                <div>Batch Complete</div>
                <button
                    onClick={() => {
                        nav('/')
                    }}
                ><Translate id={'buttons.close'} wrapper={false} /></button>
                <button
                    onClick={() => {
                    }}
                ><Translate id={'buttons.reset'} wrapper={false} /></button>
            </LoadingPane>
        }

        return <LoadingPane
            key={'completePane'}
            visible
            onClickOut={() => {
                if (context.role === 'admin') {
                    send({
                        type: 'closeOverlay',
                    })
                }
            }}
        >
            <div>Scan Complete</div>
            {context.nextFile !== undefined && <button
                onClick={() => applicationSend({
                    type: 'selectFile',
                    file: context.nextFile!,
                })}
            ><Translate id={'buttons.next'} wrapper={false} /></button>}
            <HasRole any={ViewerGroup}>
                <button
                    onClick={() => applicationSend({
                        type: 'resetFile',
                    })}
                ><Translate id={'buttons.reset'} wrapper={false} /></button>
            </HasRole>
        </LoadingPane>
    }

    return <>
        <LoadingPane
            fullscreen={true}
            key={'savingPane'}
            visible={currentState === 'savingFile'}
        >Saving...</LoadingPane>
        <LoadingPane
            fullscreen={true}
            key={'updatingPane'}
            visible={currentState === 'updatingFile'}
        >Updating...</LoadingPane>
    </>

}

const mvConfig: ModelViewerConfig = {
    rescale: [1, 1, 1], //[context.data!.dimensions.width, context.data!.dimensions.height, context.data!.dimensions.depth],
    colors: {
        boundingBox: '#f00',
        customBoundingBox: '#000',
    },
    labelList: allLabels,
    editable: true,
}


const DemoLayout: React.FC<{ debug?: boolean, viewRef?: React.LegacyRef<HTMLCanvasElement> }> = ({ debug, viewRef }) => {
    const { context, send, currentState } = useFile()
    const { context: applicationContext, send: applicationSend, currentState: applicationCurrentState } = useApplication()
    // const { conclusions, customConclusions, selectedConclusion } = useScanConclusions(context?.results?.conclusions ?? [], context.customConclusions, context.groundTruths, context, context.selection)

    return <div
        key={'scanViewContent'}
        className={styles.scanViewContent}
    >
        <Helmet>
            <title>{fileToTitle(context.protocol, context.file!, context?.store ?? context.basePath)}</title>
        </Helmet>
        <ModelViewerContainer
            key={'modelContain'}
            id={context.scanId!}
            debug={debug}
            meta={{
                file: context.file!.path,
                path: context.path!,
            }}
            model={context.data!}
            selection={(currentState === 'editingFile' ? undefined : context.selection) ?? {}}
            group={[]}
            onLock={() => send({
                type: 'enterLockMode'
            })}
            onUnlock={() => send({
                type: 'exitEditMode'
            })}
        >
            <PageActions
                key={'pageActions'}
            />
            <button style={{
                position: 'absolute',
                bottom: '1em',
                left: '50%',
                fontSize: '1.5em',
                width: '10em',
                backgroundColor: '#333',
                color: '#eee',
                fontWeight: 200,
                marginLeft: '-5em',
                textTransform: 'uppercase',
                fontFamily: 'sans-serif',
                padding: '0.25em',
                zIndex: 1000,
                cursor: 'pointer',
            }} onClick={()=>{
                // eslint-disable-next-line no-restricted-globals
                if (confirm('Select this scan?')) {
                    console.log('SELECTED SCAN', context.file)
                    triggerScan(context.file?.name!)
                }
            }}>Select Scan</button>
            <DemoFileControls
                visible={['viewingFile', 'completedFile', 'approvingFile'].includes(currentState)}
                key={'reportControls'}
                threatCount={0}
                customConclusions={[]}
            />
            <ModelViewerTools
                options={{
                    renderStyles: [RenderStyle.Iso, RenderStyle.Mip],
                }}
                key={'mvTools'}
            />
            <ModelViewerRenderer
                key={'mvPane'}
                controls
                onUpdateConclusion={(id, scanId, data) =>
                    send({
                        type: 'updateConclusion',
                        id,
                        scanId,
                        data,
                    })}
                onPointerMissed={undefined}
                onSelectConclusion={(id, scanId, mode) => {
                    if (mode === 0) {
                        return send({
                            type: 'setSelection',
                            id,
                            scanId,
                        })
                    } else if (mode === 1) {
                        return send({
                            type: 'removeCustomConclusion',
                            id,
                            scanId,
                        })
                    }
                }}
                conclusions={[]}
                customConclusions={[]}
                style={{
                    position: 'absolute',
                    inset: 0,
                    userSelect: 'none',
                }}
                canvasRef={viewRef}
                config={mvConfig}
            >
                <GizmoHelper
                    alignment={'bottom-right'}
                    margin={[100, 50]}
                >
                    <group scale={0.7}>
                        <GizmoViewcube />
                    </group>
                </GizmoHelper>
            </ModelViewerRenderer>
            {currentState !== 'completedFile'
                ? <ModelViewerControls
                    autoHide={false}
                    key={'mvControls'}
                    requestHd={() => {
                        console.log('request hd')
                    }}
                    fullscreen={applicationContext.view.fullscreen}
                    toggleFullscreen={() => applicationSend({
                        type: 'toggleFullscreen'
                    })}
                />
                : null}
        </ModelViewerContainer>
    </div>
}

export {
    DemoLayout,
}
