import { decodeDpm, ViewableModel } from "@bbai-dartmouth/dartmouth-model-viewer-lib"
import { NRRDLoader } from './NRRDLoader'
import camelcaseKeys from 'camelcase-keys'
import { ScanResult, ScanResultFailure } from "../models/ScanResult"
import { lookupConclusionCategory } from "../machines/fileApplicationMachine"
import { Vector3, Vector3Like } from "three"
import { GroundTruthFileItem } from "../../models"

type ViewableModelLoader = (data: Buffer | ArrayBuffer) => Promise<ViewableModel>

const loadFileNRRD: ViewableModelLoader = async (data): Promise<ViewableModel> => {
    try {
        const loader = new NRRDLoader()
        const volume = loader.parse(data) as any
        const width: number = volume.xLength
        const height: number = volume.yLength
        const depth: number = volume.zLength

        const scale: [number, number, number] = volume?.header?.spacings || [1, 1, 1]

        return {
            type: 'nrrd',
            model: volume,
            scaled: scale[0] !== 1,
            dimensions: {
                width,
                height,
                depth,
                scale,
            },
            metadata: {
                rescaleIntercept: -900,
                rescaleSlope: 100.0,
            },
        } as ViewableModel
    } catch (e) {
        throw new Error('failed to load NRRD file', { cause: e })
    }
}

const loadFileDRT: ViewableModelLoader = async (arrayBuffer) => {
    try {
        if (arrayBuffer.byteLength < 1) {
            throw new Error('invalid file length')
        }
        //@ts-ignore
        const dpm = decodeDpm(arrayBuffer)
        const { width, height, rescaleIntercept, rescaleSlope } = dpm.metadata
        const depth: number = dpm.slices.count
        const scale: [number, number, number] = [
            dpm.metadata.pixelWidth,
            dpm.metadata.pixelHeight,
            dpm.metadata.pixelDepth,
        ]

        return {
            type: 'dpm',
            model: dpm,
            scaled: scale[0] !== 1,
            dimensions: {
                width,
                height,
                depth,
                scale,
            },
            metadata: {
                rescaleIntercept,
                rescaleSlope,
            },
        } as ViewableModel
    } catch (e) {
        throw new Error('failed to load DRT file', { cause: e })
    }
}

const cleanupResultFile = (_result: any, categories?: Record<string, string>) => {
    let resultOut: ScanResultFailure | ScanResult = {
        id: 'unknown',
        conclusions: []
    }

    try {
        //@ts-ignore
        let result = (_result?.data !== undefined && _result?.data?.id !== undefined)
            ? camelcaseKeys((_result as any).data, { deep: true })
            : camelcaseKeys(_result as any, { deep: true })
        resultOut = {
            ...result,
            //@ts-ignore
            conclusions: result?.conclusions?.map((conclusion) => ({
                ...conclusion,
                category: lookupConclusionCategory(conclusion.label, categories)
            })) ?? [],
        } as ScanResult
    } catch (e: any) {
        console.warn('[applicationMachine:loadFile]', 'Failed to load json file', e?.message ?? e)
        console.log('[applicationMachine:loadFile]', 'JSON', _result)
    }
    return resultOut
}

export const pointIndexToGlobalSpace = ({ x, y, z }: Vector3Like, { width, height, depth, scale: [msx, msy, msz] }: any): Vector3 =>
    new Vector3(x, y, z)
        .sub({
            x: width * (0.5 * msx),
            y: height * (0.5 * msx),
            z: depth * (msz / msx) * (0.5 * msx),
        })
        .multiply({
            x: -1,
            y: -1,
            z: 1,
        })

export const pointsToBoundingBox = (p1: Vector3Like, p2: Vector3Like) => ({
    x1: Math.min(p1.x, p2.x),
    y1: Math.min(p1.y, p2.y),
    z1: Math.min(p1.z, p2.z),

    x2: Math.max(p1.x, p2.x),
    y2: Math.max(p1.y, p2.y),
    z2: Math.max(p1.z, p2.z),
})

export const legacyGroundTruthToGroundTruth = (dims: any, id: string, batch_id: string, scan_id: string, threat_item_id: string | null) => (gt: GroundTruthFileItem) => {
    const scaledBounds = pointsToBoundingBox(
            pointIndexToGlobalSpace({ x: gt.X1, y: gt.Y1, z: gt.Z1 }, dims),
            pointIndexToGlobalSpace({ x: gt.X2, y: gt.Y2, z: gt.Z2 }, dims))


    return {
        id,
        approval_status: gt.Status ?? 'pending',
        scan_id,
        batch_id,
        threat_item_id,
        label: gt.Label,
        user_label: gt.UserLabel ?? gt.Label,

        start_position: [scaledBounds.x1, scaledBounds.y1, scaledBounds.z1],
        end_position: [scaledBounds.x2, scaledBounds.y2, scaledBounds.z2],

        orientation: gt.Orientation !== undefined
            ? [gt.Orientation.X, gt.Orientation.Y, gt.Orientation.Z, gt.Orientation.W]
            : [0,0,0,1]
        }
}

const cleanupGroundTruthFile = (dimensions: any, _result: any, categories?: Record<string, string>) => {
    let resultOut: ScanResultFailure | ScanResult = {
        id: 'unknown',
        conclusions: []
    }

    try {
        //@ts-ignore
        console.log('GT FILE', _result)
        resultOut = {
            id: crypto.randomUUID(),
            conclusions: [],
            groundTruths: _result?.GroundTruth.map(legacyGroundTruthToGroundTruth(dimensions, crypto.randomUUID(), '', '', null)) ?? [],
        } as never as ScanResult
        console.log('ROUT', resultOut)
    } catch (e: any) {
        console.warn('[applicationMachine:loadFile]', 'Failed to load ground truth json file', e?.message ?? e)
        console.log('[applicationMachine:loadFile]', 'JSON', _result)
    }
    return resultOut
}

export {
    cleanupGroundTruthFile,
    cleanupResultFile,
    loadFileDRT,
    loadFileNRRD,
}
