import { BoundingBox, Bounds3, BoxData, EditorTool, HtmlWrapper, ScanItemResponseConclusion, ScanSelection, useModelViewer, ViewableModel, ViewAspect } from '@bbai-dartmouth/dartmouth-model-viewer-lib'
import { Box, Plane } from '@react-three/drei'
import { Color, DoubleSide, Vector3 } from 'three'
import { v4 as uuidv4 } from 'uuid'
import { setup, assign, enqueueActions } from 'xstate'
import { ConclusionOrientation, ScanConclusion } from '../models/ScanResult'
import { useMachine } from '@xstate/react'
import { useEffect, useMemo, useState } from 'react'
import { useKeyHandler } from '../lib/useKeyHandler'
import { allLabels } from '../lib/labels'
import styles from './BoundingBoxWizard.module.css'
import { pointGlobalToIndexSpace, pointsToBoundingBox } from '../lib/useScanConclusions'
import { groundTruthToConclusion, groundTruthToConclusionScaled } from '../machines/fileMachine'
import { statusToColor } from '../lib/status'
import { GroundTruthExtRecord } from '../../models'

const MIN_DIST_SECTION = 20
const MIN_DIST_PLAN = 20
const DEFAULT_SECTION_ASPECT: ViewAspect = 'left'
const DEFAULT_PLAN_ASPECT: ViewAspect = 'top'
const PLAN_ASPECT: ViewAspect[] = ['top', 'bottom']
const SECTION_ASPECT_SIDE: ViewAspect[] = ['left', 'right']
const SIDES: ViewAspect[] = ['left', 'front', 'right', 'back']

const getNextSide = (lastSide: ViewAspect, dir: number = 1): ViewAspect => {
    const viewIndex = SIDES.findIndex((side) => side === lastSide)

    let nextIndex = (viewIndex + dir) % SIDES.length
    if (nextIndex < 0) {
        nextIndex = (SIDES.length + nextIndex) % SIDES.length
    }
    return SIDES[nextIndex]
}

const getNextRotation = (lastAspect: ViewAspect, lastSide?: ViewAspect, dir: number = 1): ViewAspect | undefined => {
    if (SIDES.includes(lastAspect) && dir > 0) {
        return 'top'
    } else if (SIDES.includes(lastAspect) && dir < 0) {
        return 'bottom'
    } else if ((lastAspect === 'top' && dir > 0) || (lastAspect === 'bottom' && dir < 0)) {
        return lastSide && getNextSide(lastSide, 2)
    }
    return lastSide ?? DEFAULT_SECTION_ASPECT
}

type BBEvent = {
    type: 'dragStart'
    point: Vector3
} | {
    type: 'dragEnd'
    point: Vector3
} | {
    type: 'cancel'
} | {
    type: 'setCursorPosition'
    point: Vector3
} | {
    type: 'back'
} | {
    type: 'setViewAspect'
    aspect: ViewAspect
} | {
    type: 'switchInitialView'
    aspect: ViewAspect
} | {
    type: 'confirm'
    label: string
    orientation: ConclusionOrientation
}

interface BBInput {
    model: ViewableModel
    id: string
    category: string
    orientation: ViewAspect
}

type BBContext = {
    id: string
    category: string
    orientation: ViewAspect
    initialOrientation: ViewAspect
    lastOrientation?: ViewAspect
    meta?: {
        start: Vector3
        end: Vector3
        boundingBox?: Bounds3
        globalBoundingBox?: Bounds3
        scaledBoundingBox?: Bounds3
        position: Vector3
        offsetPosition: Vector3
        size?: [number, number, number]
        scale: [number, number, number]
        offset: Vector3
        orientation: [number, number, number]
    }
    model: ViewableModel
    startPoint?: Vector3
    endPoint?: Vector3
    startDepth?: Vector3
    endDepth?: Vector3
    result?: ScanConclusion
    cursorPosition?: Vector3
    complete: boolean
}


const getBoxDimensions = (min: Vector3, max: Vector3, offset: Vector3, scale: Vector3) => {
    const boxSize = max.clone().sub(min)
    const halfBox = boxSize.clone().multiplyScalar(0.5)
    const position = min.clone().add(halfBox)
    const offsetPosition = position.clone().add(offset.clone())
    const invertBox = halfBox.clone().multiply(scale)

    return {
        boxSize,
        halfBox,
        position,
        offsetPosition,
        invertBox,
    }
}


const boundingBoxWizardMachine = setup({
    types: {} as {
        context: BBContext
        event: BBEvent
        input: BBInput
    },
    actions: {
        setStartPoint: assign({
            startPoint: ({ event }) =>
                event.point,
        }),
        setEndPoint: assign({
            endPoint: ({ event }) =>
                event.point,
        }),
        setStartDepth: assign({
            startDepth: ({ event }) =>
                event.point,
            cursorPosition: ({ event }) =>
                event.point,
        }),
        setEndDepth: assign({
            endDepth: ({ event }) =>
                event.point,
        }),
        setCursorPosition: assign({
            cursorPosition: ({ event }) =>
                event.point,
        }),
        resetState: assign({
            startPoint: undefined,
            endPoint: undefined,
            startDepth: undefined,
            endDepth: undefined,
        }),
        setComplete: assign({
            complete: true,
        }),
        resetDepthState: assign({
            cursorPosition: undefined,
            endDepth: undefined,
            startDepth: undefined,
        }),
        setViewAspect: () => {
            throw new Error("not implemented: 'setViewAspect'")
        },
        complete: () => {
            throw new Error("not implemented: 'complete'")
        },
        enableControls: () => {
            throw new Error("not implemented: 'enableControls'")
        },
        cancelled: () => {
            throw new Error("not implemented: 'cancelled'")
        },
        toggleViewAspect: enqueueActions(({ enqueue, context }) => {
            const otherAspect = SIDES.includes(context.orientation)
                ? DEFAULT_PLAN_ASPECT
                : DEFAULT_SECTION_ASPECT
            enqueue({
                type: 'setViewAspect',
                params: { aspect: otherAspect },
            })
        }),
        recalculate: assign({
            meta: ({ context: { meta, model, startPoint, endPoint, initialOrientation, cursorPosition, startDepth, endDepth }, event }) => {
                if (event?.scale) {
                    console.debug('got scale in recalc', event)
                }
                const scale = new Vector3(...(event?.scale ?? meta?.scale ?? [1, 1, 1]))
                const orientation = event?.orientation ?? meta?.orientation ?? [0, 0]
                const offset = event?.position !== undefined
                    ? new Vector3((event?.position?.x ?? 0), event?.position?.y ?? 0, event?.position?.z ?? 0)
                    : (meta?.offset ?? new Vector3())

                if (startPoint === undefined || (endPoint === undefined && cursorPosition === undefined)) {
                    return undefined
                }

                const endRaw = endPoint ?? cursorPosition!
                const startRaw = startPoint

                const startD = endPoint
                    ? startDepth ?? cursorPosition
                    : undefined
                const endD = startD
                    ? endDepth ?? cursorPosition
                    : undefined

                const topView = PLAN_ASPECT.includes(initialOrientation)
                const sideView = SECTION_ASPECT_SIDE.includes(initialOrientation)

                const xRange = sideView
                    ? (startD
                        ? endD
                            ? [startD.x, endD.x]
                            : [startD.x]
                        : [endRaw.x, startRaw.x])
                    : [endRaw.x, startRaw.x]
                const yRange = topView
                    ? (startD
                        ? endD
                            ? [startD.y, endD.y]
                            : [startD.y]
                        : [endRaw.y, startRaw.y])
                    : [endRaw.y, startRaw.y]
                const zRange = (!topView && !sideView)
                    ? startD
                        ? endD
                            ? [startD.z, endD.z]
                            : [startD.z]
                        : [endRaw.z, startRaw.z]
                    : [endRaw.z, startRaw.z]

                const end = new Vector3(Math.max(...xRange), Math.max(...yRange), Math.max(...zRange))
                const start = new Vector3(Math.min(...xRange), Math.min(...yRange), Math.min(...zRange))

                const {
                    boxSize,
                    position,
                    offsetPosition,
                    invertBox,
                } = getBoxDimensions(start, end, offset, scale)

                const boundingBox = pointsToBoundingBox(
                    pointGlobalToIndexSpace(start, model),
                    pointGlobalToIndexSpace(end, model))

                const scaledBoundingBox = pointsToBoundingBox(
                    pointGlobalToIndexSpace(offsetPosition.clone().sub(invertBox), model),
                    pointGlobalToIndexSpace(offsetPosition.clone().add(invertBox), model))
                const globalBoundingBox = pointsToBoundingBox(
                    offsetPosition.clone().sub(invertBox),
                    offsetPosition.clone().add(invertBox))

                const size: [number, number, number] = sideView
                    ? [Math.max(2, boxSize.x), boxSize.y, boxSize.z]
                    : [boxSize.x, boxSize.y, Math.max(2, boxSize.z)]

                return {
                    position,
                    offsetPosition,
                    size,
                    start,
                    end,
                    boundingBox,
                    scaledBoundingBox,
                    globalBoundingBox,
                    scale: scale.toArray(),
                    orientation,
                    offset,
                }
            }
        })
    },
    guards: {
        checkMinDistancePlan: ({ event, context }) =>
            context?.startDepth !== undefined &&
            (PLAN_ASPECT.includes(context.initialOrientation)
                ? (event?.point?.y !== undefined && Math.abs(event.point.y - context.startDepth.y) > MIN_DIST_SECTION)
                : (SECTION_ASPECT_SIDE.includes(context.initialOrientation)
                    ? (event?.point?.x !== undefined && Math.abs(event.point.x - context.startDepth.x) > MIN_DIST_PLAN)
                    : (event?.point?.z !== undefined && Math.abs(event.point.z - context.startDepth.z) > MIN_DIST_PLAN))),
        checkMinDistanceSection: ({ event, context }) => {
            if (context?.startPoint === undefined || event?.point === undefined) {
                return false
            }
            const dims = (PLAN_ASPECT.includes(context.initialOrientation)
                ? [
                    event.point.x - context.startPoint.x,
                    event.point.z - context.startPoint.z,
                ]
                : (SECTION_ASPECT_SIDE.includes(context.initialOrientation)
                    ? [
                        event.point.y - context.startPoint.y,
                        event.point.z - context.startPoint.z,
                    ]
                    : [
                        event.point.x - context.startPoint.x,
                        event.point.y - context.startPoint.y,
                    ])
            ).map(Math.abs)

            const minSide = Math.min(...dims)
            if (minSide < MIN_DIST_SECTION) {
                return false
            }
            return true
        }
    },
}).createMachine({
    id: 'boundingBoxWizard',
    initial: 'sectionView',
    context: ({ input: { model, id, category, orientation } }) => ({
        model,
        id,
        category,
        orientation,
        initialOrientation: orientation,
        startPoint: undefined,
        endPoint: undefined,
        startDepth: undefined,
        endDepth: undefined,
        result: undefined,
        complete: false,
    }),
    on: {
        setCursorPosition: {
            actions: ['setCursorPosition', 'recalculate'],
        },
    },
    states: {
        sectionView: {
            description: 'Initial section view to select first 2 points that describe a 2D area.',
            entry: ['resetState', 'recalculate'],
            on: {
                switchInitialView: {
                    actions: [{ type: 'setViewAspect', params: { setInitial: true } }]
                },
                dragStart: {
                    actions: ['setStartPoint', 'recalculate'],
                },
                dragEnd: [
                    {
                        guard: 'checkMinDistanceSection',
                        actions: ['setEndPoint', 'recalculate'],
                        target: 'planView',
                    },
                    {
                        actions: ['resetState', 'recalculate'],
                    },
                ],
                cancel: {
                    actions: ['resetState', 'recalculate'],
                },
            },
        },
        planView: {
            description: 'Overhead view to select start and end z coordinates.',
            entry: ['resetDepthState', 'recalculate', { type: 'toggleViewAspect' }],
            on: {
                dragStart: {
                    actions: ['setStartDepth', 'recalculate'],
                },
                dragEnd: [
                    {
                        guard: 'checkMinDistancePlan',
                        actions: ['setEndDepth', 'recalculate'],
                        target: 'confirm',
                    },
                    {
                        actions: ['resetDepthState', 'recalculate'],
                    }
                ],
                cancel: {
                    actions: ['resetDepthState', 'recalculate'],
                },
            },
        },
        confirm: {
            entry: ['setComplete', 'enableControls', 'recalculate'],
            on: {
                confirm: {
                    target: 'complete',
                },
            },
        },
        complete: {
            description: 'The definition of a bounding volume has been completed.',
            entry: ['recalculate', 'complete'],
        },
        cancelled: {
            entry: ['cancelled'],
        }
    },
})

interface BoundingBoxResult {
    id: string
    label: string
    userLabel: string
    boundingBox: Bounds3
    globalBoundingBox: Bounds3
    orientation?: ConclusionOrientation
}

interface BoundingBoxWizardProps {
    initialOrientation?: ViewAspect
    category?: string
    id?: string
    debug?: boolean
    onComplete: (result: BoundingBoxResult) => void
    onCancel: () => void
}

const STATE_MESSAGE: Record<any, string> = {
    sectionView: 'Select the side you would like to start on using up/down/left/right keys, then drag and drop to outline the item.',
    planView: 'Now drag to enclose the start and end points of the item',
    confirm: 'Finally make any adjustments needed, and select save to complete',
}

export const BoundingBoxWizard: React.FC<BoundingBoxWizardProps> = ({ onCancel, debug, onComplete, id, category = 'unknown', initialOrientation = DEFAULT_PLAN_ASPECT }) => {
    const { send: modelViewerSend, context: modelViewerContext } = useModelViewer()

    const [{ value: currentState, context }, send] = useMachine(boundingBoxWizardMachine.provide({
        actions: {
            enableControls: () => {
                modelViewerSend({
                    type: 'enableControls'
                })
                modelViewerSend({
                    type: 'restoreCameraPosition'
                })
            },
            cancelled: () => {
                console.warn('not implemented; cancelled')
            },
            complete: ({ context: selfContext, event }) => {
                onComplete({
                    id: selfContext.id,
                    label: category ?? 'unknown',
                    userLabel: event.label,
                    globalBoundingBox: selfContext.meta?.globalBoundingBox!,
                    boundingBox: selfContext.meta?.scaledBoundingBox!,
                    orientation: selfContext.meta?.orientation,
                })
                modelViewerSend({
                    type: 'exitEditing',
                    restore: !selfContext.complete,
                })
            },
            setViewAspect: enqueueActions(({ enqueue, event, context: selfContext }, params: any) => {
                const aspect = event?.aspect ?? params?.aspect
                if (aspect === undefined) {
                    return
                }
                if (params?.setInitial) {
                    enqueue.assign({
                        orientation: aspect,
                        initialOrientation: aspect,
                        lastOrientation: selfContext.orientation,
                    })
                } else {
                    enqueue.assign({
                        orientation: aspect,
                        lastOrientation: selfContext.orientation,
                    })
                }
                modelViewerSend({
                    type: 'setViewAspect',
                    aspect: aspect
                })
            }),
        },
    }), {
        input: {
            model: modelViewerContext.model,
            category: category,
            id: id ?? uuidv4(),
            orientation: initialOrientation,
        },
    })


    const { showBoundingBox, visible, planePosition, planeRotation } = useMemo(() => ({
        planeRotation: [
            (PLAN_ASPECT.includes(context.orientation)
                ? Math.PI * 0.5
                : 0),
            (SECTION_ASPECT_SIDE.includes(context.orientation)
                ? Math.PI * 0.5
                : 0),
            0
        ] as [number, number, number],
        planePosition: SECTION_ASPECT_SIDE.includes(context.orientation)
            ? undefined
            : new Vector3(0, context.meta?.end?.y, 0),
        showBoundingBox: context?.cursorPosition &&
            (context.meta?.position && context.meta?.size) &&
            ['confirm', 'planView', 'sectionView'].includes(currentState),
        visible: ['confirm', 'planView', 'sectionView'].includes(currentState)
    }), [context, currentState])

    useEffect(() => {
        if (modelViewerContext.subMode) {
            return onCancel()
        }
        modelViewerSend({
            type: 'enterEditing',
            aspect: initialOrientation
        })

        return () =>
            modelViewerSend({
                type: 'exitEditing',
                restore: true,
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useKeyHandler((key, { ctrlKey }) => {
        if (currentState !== 'planView' && context.startPoint === undefined && !ctrlKey) {
            if (['ArrowLeft', 'ArrowRight'].includes(key)) {
                const dir = key === 'ArrowRight' ? -1 : 1
                return send({
                    type: 'switchInitialView',
                    aspect: getNextSide(context.orientation, dir),
                })
            } else if (['ArrowUp', 'ArrowDown'].includes(key)) {
                const dir = key === 'ArrowDown' ? -1 : 1
                const nextAspect = getNextRotation(context.orientation, context.lastOrientation, dir)
                if (nextAspect === undefined) {
                    return
                }
                return send({
                    type: 'switchInitialView',
                    aspect: nextAspect,
                })
            }
        }
    })

    const [boxData, setBoxData] = useState<BoxData>({
        id: context.id,
        position: { x: 0, y: 0, z: 0 },
        orientation: { x: 0, y: 0, z: 0 },
        scale: { x: 1, y: 1, z: 1 },
        label: category,
    })

    return visible ? <group
        key={'selectionGroup'}
    >
        {STATE_MESSAGE?.[currentState] !== undefined
            ? <HtmlWrapper className={styles.boundingBoxWizardPrompt}>
                hint:
                <div
                    className={styles.boundingBoxWizardPromptMessage}
                >{STATE_MESSAGE?.[currentState]}</div>
            </HtmlWrapper>
            : null}
        {currentState === 'confirm'
            ? <EditorTool
                title={'Create Ground Truth'}
                labelList={allLabels}

                data={boxData}
                setData={(data) =>
                    setBoxData(data)}

                onReject={() =>
                    send({
                        type: 'exitEditing'
                    })}
                onResolve={({ label, position, orientation, scale }) =>
                    send({
                        type: 'confirm',
                        label,
                        position,
                        orientation: [orientation.x, orientation.y, orientation.z],
                        scale: [scale.x, scale.y, scale.z],
                    })}
            /> : null}
        <Plane
            key={'selectionPlane'}
            renderOrder={0}
            visible={debug ?? false}
            rotation={planeRotation}

            position={planePosition}
            args={[2000, 2000]}

            material-color={new Color(1, 0, 1)}
            material-transparent={true}
            material-opacity={0.4}
            material-side={DoubleSide}
            material-depthTest={false}

            onPointerMove={(e) => send({
                type: 'setCursorPosition',
                point: e.point,
            })}
            onPointerDown={(e) => {
                e.stopPropagation()
                send({
                    type: 'dragStart',
                    point: e.point,
                })
            }}
            onPointerUp={(e) => {
                e.stopPropagation()
                send({
                    type: 'dragEnd',
                    point: e.point,
                })
            }}
        />
        {showBoundingBox && <group
            rotation-x={boxData.orientation.x}
            rotation-y={boxData.orientation.y}
            rotation-z={boxData.orientation.z}
            // scale={[boxData.scale.x, boxData.scale.y, boxData.scale.z]}
            position={context?.meta?.position.clone().add(boxData.position)}
        >
            <Box
                renderOrder={10}
                material-color={new Color(0, 0, 0)}
                // position={0}
                material-transparent={true}
                material-opacity={0.4}
                material-depthTest={false}
                args={context?.meta?.size ?
                    [context.meta.size[0] * boxData.scale.x, context.meta.size[1] * boxData.scale.y, context.meta.size[2] * boxData.scale.z]
                    : [1, 1, 1]}
            />
            <BoundingBox
                scanId={'none'}
                type={'slab'}
                selected={false}
                config={{
                    colors: {
                        boundingBox: '#000'
                    }
                }}
                boundingBox={{
                    x1: (context?.meta?.size?.[0] ?? 0) * -0.5 * boxData.scale.x,
                    y1: (context?.meta?.size?.[1] ?? 0) * -0.5 * boxData.scale.y,
                    z1: (context?.meta?.size?.[2] ?? 0) * -0.5 * boxData.scale.z,
                    x2: (context?.meta?.size?.[0] ?? 0) * 0.5 * boxData.scale.x,
                    y2: (context?.meta?.size?.[1] ?? 0) * 0.5 * boxData.scale.y,
                    z2: (context?.meta?.size?.[2] ?? 0) * 0.5 * boxData.scale.z,
                }}
            />
        </group>}
    </group> : null
}

interface DebugBoundingBoxesProps {
    conclusions: ScanItemResponseConclusion[]
    groundTruths?: GroundTruthExtRecord[]
    selection?: ScanSelection
    scanId: string
    onSelect?: (id: string, scanId: string, mode: number) => void
    onUpdate?: (conclusionId: string, scanId: string, data: BoxData) => void
    color?: string
}

export const DebugBoundingBoxes: React.FC<DebugBoundingBoxesProps> = ({ scanId, selection, onSelect, onUpdate, conclusions, groundTruths, color = '#0f0' }) => {
    const conclusionsAll = useMemo(() => ([
        ...conclusions.filter((conclusion) =>
            !groundTruths?.map(({ id }) =>
                id).includes(conclusion.id)),
        ...(groundTruths?.map(groundTruthToConclusion) ?? [])]), [conclusions, groundTruths, selection])

    return conclusionsAll.map(({ id, globalBoundingBox, orientation, ...props }) => {
        if (!globalBoundingBox) {
            return null
        }

        //@ts-ignore
        const status = props?.approval_status ?? 'draft'
        const isSelectable = ['pending', 'draft', 'approved'].includes(status)
        return <BoundingBox
            key={`${id}_${status}_${isSelectable}`}
            id={isSelectable ? id : undefined}
            // maskId={1}
            scanId={scanId}
            type={'box'}
            selected={isSelectable && (selection?.id === id)}
            onSelect={onSelect}
            onEdited={onUpdate}
            config={{
                colors: {
                    boundingBox: ['rejected', 'approved'].includes(status)
                        ? '#000'
                        : statusToColor(status)
                },
            }}
            orientation={orientation}
            boundingBox={globalBoundingBox}
        />
    })
}