import React, { useState, useEffect } from "react";
import { TaskStatusItem } from "./types";
import { CommandForgeUtils } from "./CommandForgeUtils";
import { config } from "../../config";
import { EventSourcePolyfill } from "event-source-polyfill";
import { useAuthenticationRef } from "../../containers/AuthorisationContainer";

const TaskHistory: React.FC<{ taskId: string }> = ({ taskId }) => {
    const [history, setHistory] = useState<TaskStatusItem[]>([]);
    const authRef = useAuthenticationRef();

    useEffect(() => {
        const eventSource = new EventSourcePolyfill(`${config.schedulerApi}/sse/tasks/${taskId}/history`, {
            headers: { authorization: `Bearer ${authRef.current}` },
        });

        eventSource.onmessage = (event) => {
            const rawData = JSON.parse(event.data);
            const transformedTasks: TaskStatusItem[] = rawData.map((item: string[]) => ({
                status: item[0],
                createdDate: parseInt(item[1]),
                createdDateString: CommandForgeUtils.formatRelativeTime(parseInt(item[1])),
                message: item[2],
            })).sort((a: TaskStatusItem, b: TaskStatusItem) => b.createdDate - a.createdDate);

            setHistory(transformedTasks);
        };
        return () => eventSource.close();
    }, [authRef, taskId]);

    return (
        <>
            History:
            <ul>
                {history?.map((history, index) => (
                    <li key={index}>
                        {history.status}: {history.createdDateString} {history.message}
                    </li>
                ))}
            </ul>
        </>
    );
};

export default TaskHistory;
