import React, { useState, useEffect, useRef } from "react";
import { EventSourcePolyfill } from "event-source-polyfill";
import { Task } from "./types";
import { useAuthenticationRef } from "../../containers/AuthorisationContainer";
import { config } from "../../config";
import TaskItem from "./TaskItem";
import { useTaskDetailsCache } from "./TaskCacheProvider";
import Filters from "./Filters";
import { CommandForgeUtils } from "./CommandForgeUtils";
import Pagination from "./Pagination";

import styles from "./TaskList.module.css";
import TaskForm from "./TaskForm";
import { FiPlus } from "react-icons/fi";
import { useCopyHandler } from "../../hooks/useCopyHandler";

const TaskList: React.FC = () => {
    const rootTaskUrl = `${config.schedulerApi}/sse/tasks`;
    const [filterQuery, setFilterQuery] = useState<string>("");
    const [pagingQuery, setPagingQuery] = useState<string>("");
    const [tasks, setTasks] = useState<Task[]>([]);
    const [expandedTaskId, setExpandedTaskId] = useState<string | null>(null);
    const [pageNumber, setPageNumber] = useState<number>(1)
    const [pageCount, setPageCount] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(100);
    const [totalRecords, setTotalRecords] = useState<number>(0);
    const taskListRef = useRef<HTMLDivElement | null>(null);
    const [isFormVisible, setIsFormVisible] = useState(false);

    const { fetchTaskDetails, taskDetails } = useTaskDetailsCache();

    const authRef = useAuthenticationRef();

    useCopyHandler(taskListRef);

    useEffect(() => {
        setPagingQuery(`pageNumber=${pageNumber}&pageSize=${pageSize}`);
    }, [pageNumber, pageSize]);

    useEffect(() => {
        const currentRecord = (pageNumber - 1) * pageSize + 1;
        const lastRecord = Math.min(pageNumber * pageSize, totalRecords);
        if (currentRecord > lastRecord) {
            setPageNumber(1);
        }

    }, [pageNumber, pageSize, totalRecords]);

    useEffect(() => {
        const eventSource = new EventSourcePolyfill(rootTaskUrl + (filterQuery || pagingQuery ? `?${[filterQuery, pagingQuery].filter(Boolean).join("&")}` : ""), {
            headers: { authorization: `Bearer ${authRef.current}` },
        });

        eventSource.onmessage = (event) => {
            const rawData = JSON.parse(event.data);
            const transformedTasks: Task[] = rawData.data?.map((item: string[]) => ({
                id: item[0],
                status: item[1],
                createdDate: parseInt(item[2]),
                createdDateString: CommandForgeUtils.formatRelativeTime(parseInt(item[2])),
                message: item[3],
            })).sort((a: Task, b: Task) => b.id.localeCompare(a.id));

            setTasks(transformedTasks);

            setTasks(transformedTasks);
            setPageNumber(rawData.pageNumber);
            setPageCount(rawData.pageCount);
            setPageSize(rawData.pageSize);
            setTotalRecords(rawData.totalRecords);
        };

        return () => {
            eventSource.close();
        };
    }, [pageNumber, pageCount, pageSize, totalRecords, rootTaskUrl, filterQuery, pagingQuery, authRef]);

    useEffect(() => {
        const uncachedTaskIds = tasks
            .map((task) => task.id)
            .filter((id) => !taskDetails[id]);
        if (uncachedTaskIds.length > 0) {
            fetchTaskDetails(uncachedTaskIds);
        }
    }, [tasks, taskDetails, fetchTaskDetails]);

    useEffect(() => {
        const uncachedTaskIds = tasks
            .map((task) => task.id)
            .filter((id) => !taskDetails[id]);

        if (uncachedTaskIds.length > 0) {
            fetchTaskDetails(uncachedTaskIds);
        }
    }, [tasks, taskDetails, fetchTaskDetails]);

    const handleFiltersChange = (updatedUrl: string) => {
        setFilterQuery(updatedUrl);
    };

    const handleExpand = (taskId: string) => {
        setExpandedTaskId((prev) => (prev === taskId ? null : taskId));
    };

    return (
        <>
            <div className={styles.filterPaginationContainer}>
                <Filters onFiltersChange={handleFiltersChange}/>
                <Pagination
                    totalRecords={totalRecords}
                    pageSize={pageSize}
                    currentPage={pageNumber}
                    totalPages={pageCount}
                    onPageChange={(page) => setPageNumber(page)}
                    onPageSizeChange={(size) => setPageSize(size)}
                />
                <div>
                    <button
                        onClick={() => setIsFormVisible(!isFormVisible)}
                        className={styles.addTaskButton}
                    >
                        <FiPlus size={24}/>
                    </button>

                    {isFormVisible && (
                        <TaskForm onClose={() => setIsFormVisible(false)}/>
                    )}
                </div>

            </div>
            <div className={styles.taskList} ref={taskListRef}>
                {tasks.length > 0 ? (
                    tasks.map((task) => (
                        <TaskItem
                            key={task.id}
                            task={task}
                            isExpanded={expandedTaskId === task.id}
                            onExpand={() => handleExpand(task.id)}
                            taskDetails={taskDetails[task.id]}
                        />
                    ))
                ) : (
                    <div></div>
                )}
            </div>
        </>
    );
};

export default TaskList;
