import { Bounds3, ScanItemResponseConclusion, ScanSelection, ViewableModel } from '@bbai-dartmouth/dartmouth-model-viewer-lib';
import type { ScanConclusion } from '../models/ScanResult'
import { useMemo } from 'react';
import { Vector3, Vector3Like } from 'three';
import { FileContext, groundTruthToConclusion, groundTruthToConclusionScaled } from '../machines/fileMachine';
import { GroundTruthExtRecord } from '../../models';

export const flipConclusionXY = (model: ViewableModel) => (conclusion: ScanItemResponseConclusion): ScanItemResponseConclusion =>
({
    ...conclusion,
    boundingBox: {
        ...conclusion.boundingBox,
        x1: (model.dimensions.width * model.dimensions.scale[0]) - conclusion.boundingBox.x2,
        x2: (model.dimensions.width * model.dimensions.scale[0]) - conclusion.boundingBox.x1,
        y1: (model.dimensions.height * model.dimensions.scale[1]) - conclusion.boundingBox.y2,
        y2: (model.dimensions.height * model.dimensions.scale[1]) - conclusion.boundingBox.y1
    }
})

const toScanItemResponseConclusion = (conclusion: ScanConclusion): ScanItemResponseConclusion => ({
    ...conclusion,
    category: conclusion?.category ?? conclusion.label,
    userLabel: conclusion.label,
})


export const pointsToBoundingBox = (p1: Vector3Like, p2: Vector3Like): Bounds3 => ({
    x1: Math.min(p1.x, p2.x),
    y1: Math.min(p1.y, p2.y),
    z1: Math.min(p1.z, p2.z),

    x2: Math.max(p1.x, p2.x),
    y2: Math.max(p1.y, p2.y),
    z2: Math.max(p1.z, p2.z),
})

export const pointGlobalToIndexSpace = ({ x, y, z }: Vector3Like, { dimensions: { width, height, depth, scale: [msx, msy, msz] } }: ViewableModel): Vector3 =>
    new Vector3(x, y, z)
        .divide({
            x: msx,
            y: msy,
            z: msz,
        }).multiply({
            x: -1,
            y: -1,
            z: 1,
        }).add({
            x: width * 0.5,
            y: height * 0.5,
            z: depth * 0.5,
        }).multiply({
            x: msx,
            y: msx,
            z: msx,
        })

export const pointIndexToGlobalSpace = ({ x, y, z }: Vector3Like, { dimensions: { width, height, depth, scale: [msx, msy, msz] } }: ViewableModel): Vector3 =>
    new Vector3(x, y, z)
        .sub({
            x: width * (0.5 * msx),
            y: height * (0.5 * msx),
            z: depth * (msz / msx) * (0.5 * msx),
        })
        .multiply({
            x: -1,
            y: -1,
            z: 1,
        })


const rescaleConclusion = (model: ViewableModel) =>
    (conclusion: ScanConclusion): ScanConclusion => {
        const [widthScale, , depthScale] = model.dimensions.scale
        const baseDepthScale = depthScale / widthScale

        const startPoint = {
            x: conclusion.boundingBox.x1,
            y: conclusion.boundingBox.y1,
            z: conclusion.boundingBox.z1 * baseDepthScale,
        }
        const endPoint = {
            x: conclusion.boundingBox.x2,
            y: conclusion.boundingBox.y2,
            z: conclusion.boundingBox.z2 * baseDepthScale
        }

        const globalBoundingBox = pointsToBoundingBox(
            pointIndexToGlobalSpace(startPoint, model),
            pointIndexToGlobalSpace(endPoint, model))

        const boundingBox = pointsToBoundingBox(
            startPoint,
            endPoint)

        return {
            ...conclusion,
            boundingBox,
            globalBoundingBox,
        }
    }

export const unscaleConclusion = (model: ViewableModel) =>
    (conclusion: ScanConclusion): ScanConclusion => {
        const [widthScale, , depthScale] = model.dimensions.scale
        const baseDepthScale = depthScale / widthScale

        return {
            ...conclusion,
            boundingBox: {
                x1: conclusion.boundingBox.x1,
                y1: conclusion.boundingBox.y1,
                z1: conclusion.boundingBox.z1 / baseDepthScale,

                x2: conclusion.boundingBox.x2,
                y2: conclusion.boundingBox.y2,
                z2: conclusion.boundingBox.z2 / baseDepthScale,
            }
        }
    }

const makeInvisible = (conclusion: ScanItemResponseConclusion): ScanItemResponseConclusion => ({
    ...conclusion,
    hidden: true,
})

interface ContextConclusions {
    ref: string
    selectedConclusion?: ScanItemResponseConclusion
    conclusions: ScanItemResponseConclusion[]
    customConclusions: ScanItemResponseConclusion[]
}

const useScanConclusions = (rawConclusions: ScanConclusion[], rawCustomConclusions: ScanConclusion[], groundTruths: GroundTruthExtRecord[], context: FileContext, selection?: ScanSelection): ContextConclusions => {
    const conclusions = useMemo(() =>
        rawConclusions
            .map(rescaleConclusion(context.data!))
            .map(toScanItemResponseConclusion)
            .map(flipConclusionXY(context.data!))
        // eslint-disable-next-line react-hooks/exhaustive-deps
        , [rawConclusions, rawCustomConclusions])

    const customConclusions = useMemo(() => [
        ...(groundTruths
            ? groundTruths
                .filter((gt) =>
                    !rawCustomConclusions.map(({ id }) =>
                        id).includes(gt.id))
                .map(gt =>
                    groundTruthToConclusionScaled(gt, context.data!))
                .map(rescaleConclusion(context.data!))
                .map(toScanItemResponseConclusion)
                .map(flipConclusionXY(context.data!))
                .map(makeInvisible)
            : []),
        ...rawCustomConclusions
            .map(rescaleConclusion(context.data!))
            .map(toScanItemResponseConclusion)
            .map(flipConclusionXY(context.data!)),
    ]
        // eslint-disable-next-line react-hooks/exhaustive-deps
        , [rawCustomConclusions, groundTruths])

    const selectedConclusion = useMemo(() =>
        [...customConclusions, ...conclusions].find((c) =>
            c.id === context.selection?.id)
        // eslint-disable-next-line react-hooks/exhaustive-deps
        , [conclusions, customConclusions, selection])

    return {
        ref: `${context.scanId}_${customConclusions.length}`,
        selectedConclusion,
        conclusions,
        customConclusions,
    }
}

export {
    useScanConclusions,
}
